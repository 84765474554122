<template>
  <section id="home-corporate" class="home-corporate">
    <b-container
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="false"
      data-aos-once="false"
      data-aos-anchor-placement="bottom"
      class="corporate-container"
      fluid
    >
      <b-row class="home-corporate-title wow fadeInUp" data-wow-delay="0.5s">
        <b-col md="12">
          <div>
            <h1 class="home-item-title">{{ $t("corporate_title_0") }}</h1>
            <div class="bord-bot"></div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="10" class="vision-video mx-auto">
          <div
            class="wow fadeInUp mobile-video mt-5">
            <video
              ref="myVideo"
              src="@/assets/asuka_video.mp4"
              type="video/mp4"
              autoplay
              playsinline
              muted
              class="home-video"
            ></video>
          </div>
          <div class="mobile-img">
            <img src="@/assets/all-service.png">
          </div>
        </b-col>
        <b-col sm="12" class="vision-bg pb-5">
          <div
            class="mx-auto"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h1 class="vision-txt mt-5">VISION</h1>
            <h1 class="mt-5 font-weight-bold-vision">
              「総合人材サービス国際的なキャリアの架け橋」
            </h1>
            <div class="text-introduce pr-2 pl-2">
              <p class="text-center text-white-space mt-5">
                {{ $t("corporate_introduce_1") }}
              </p>
              <!-- <p class="text-center text-white-space">
                {{ $t("corporate_introduce_2") }}
              </p>
              <p class="text-center text-white-space">
                {{ $t("corporate_introduce_3") }}
              </p> -->
              <p></p>
            </div>
          </div>
          <button class="advance-show mt-5" @click="handleMovePage('overview')"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            もっと見る
          </button>
        </b-col>

        <b-col md="6">
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "home-corporate",
  mounted() {
    const videoElement = this.$refs.myVideo;
    videoElement.playbackRate = 1.8;
  },
  methods: {
    handleMovePage(pagePath) {
      this.$router.push(pagePath);
    },
  },
};
</script>

<style>
#home-corporate {
  /* background: #e9ecef;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  /* background-image: url("../assets/asuka-bg-2.jpg"); */
  /* background-size: cover; */
  font-size: 1.1rem;
  padding-top: 0px;
  padding-bottom: 0;
  margin-bottom: 0;
}
@media (min-width: 2400px) {
  .corporate-container {
    max-width: 1480px;
  }
}

.vision-bg {
  background-image: url("../assets/asuka_bg.png");
  background-size: cover;
  margin-top: 10%;
}
.home-video {
  width: 80%;
}
.text-white-space {
  white-space: pre;
}

@media (min-width: 768px) {
  .text-introduce {
    width: 90%;
    margin: auto;
  }
}
@media (max-width: 767px) {
  .text-white-space {
    white-space: unset !important;
  }
}
.corporate-intro-item {
  background-color: #111c55;
  width: 88px;
  height: 40px;
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 17px;
  align-items: center;
  padding-left: 7px;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}
.corporate-company-image {
  border-radius: 16px;
}
.vision-txt {
  font-family: "Montserrat", "Hiragino Kaku Gothic ProN", "meiryo", sans-serif;
  letter-spacing: 0.1em;
  font-size: 2rem;
  padding-right: 10px;
  padding-left: 10px;
}
.font-weight-bold-vision {
  font-weight: 400;
  color: #467ca6;
  font-size: 2.5rem !important;
}
.mobile-img {
  display: none;
}
.mobile-video {
  padding: 5%;
}
@media (max-width: 576px) {
  .vision-txt {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 768px) {
  .font-weight-bold-vision {
    font-size: 1.5rem !important;
  }
  .mobile-img {
    display: block;
    width: 100%;
    padding: 10% 7%;
  }
    .mobile-img img{
    width: 100%;
  }
  .mobile-video {
    display: none;
  }
}
</style>