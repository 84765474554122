import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ForeignInfo from '@/views/ForeignInfo.vue'
import Corporate from "@/views/CorporateInfo.vue"
import EngineerInfo from "@/views/EngineerInfo.vue"
import TokuteiInfo from "@/views/TokuteiInfo.vue"
import AnttComp from "@/views/AnttComp.vue"
import MhaComp from "@/views/MhaComp.vue"
import CvtechComp from "@/views/CvtechComp.vue"
import Contact from "@/views/Contact.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/foreign-info',
    name: 'foreign-info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ForeignInfo
  },
  {
    path:'/overview',
    name:"overview",
    component:Corporate
  },
  {
    path:'/visa-engineer',
    name:"visa-engineer",
    component:EngineerInfo
  },
  {
    path:'/visa-tokuteigino',
    name:"visa-tokuteigino",
    component:TokuteiInfo
  },
  {
    path:'/company-ant',
    name:"company-ant",
    component:AnttComp
  },
  {
    path:'/company-mha',
    name:"company-mha",
    component:MhaComp
  },
  {
    path:'/company-cvtech',
    name:"company-cvtech",
    component:CvtechComp
  },
  {
    path:'/contact',
    name:"contact",
    component:Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savesPosition) {
    if (savesPosition) {
      return savesPosition
    }else {
      return {x:0,y:0};
    }
  }
})

export default router
