import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tab:1
  },
  mutations: {
    SET_TAB(state,num) {
      state.tab = num;
    }
  },
  actions: {
    setTab({commit},number) {
      commit("SET_TAB",number)
    }
  },
  getters: {
    tab(state) {
      return state.tab;
    }
  }
})
