<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
      <!-- TOPBAR 
        =================-->
    <!-- <topBar />    -->
    <!-- NAVIGATION 
        =================-->
    <naviBar />    
    <router-view/>
    <homeFooter />
  </div>
</template>
<script>
// import topBar from '@/components/TopBar.vue'
import naviBar from '@/components/Navigation.vue'
import homeFooter from '@/components/Footer.vue'
export default {
  name:"App",
  components: {
    // topBar,
    naviBar,
    homeFooter
  }
  
}
</script>

<style>
#app {
  font-family:  "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "meiryo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  font-size: 14px;
  overflow-x: hidden;
    font-weight: 500;
}
body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/* border-radius: 10px; */
	/* background-color: #F5F5F5; */
}

body::-webkit-scrollbar
{
	width: 12px;
	/* background-color: #F5F5F5; */
}

body::-webkit-scrollbar-thumb
{
	/* border-radius: 10px; */
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #111c55;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #111c55;
}

/* #nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
