<template>
  <section id="home-teams" class="home-teams">
    <b-container class="team-container" fluid>
      <b-row class="home-teams-title wow fadeInUp" data-wow-delay="0.3s">
        <b-col sm="12">
          <div>
            <h1 class="home-item-title">{{ $t("teams_title_0") }}</h1>
            <div class="bord-bot"></div>
          </div>
        </b-col>
      </b-row>
      <b-row class="home-teams-list">
        <b-col sm="12" class="mb-5">
          <div
            class="mx-auto"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h1 class="vision-txt mt-5">Achievements</h1>
            <h2 class="mt-5 font-weight-bold-vision">
              「毎年、成功と夢が実現」
            </h2>
            <div class="text-introduce">
              <p class="text-center mt-5 text-white-space">
                {{ $t("achievement_introduc") }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col
          md="4"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="500"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="home-teams-member">
            <figure>
              <img
                :style="memberImgStyle"
                src="../assets/handshake.png"
                class="teams-icon"
                alt="株式会社ASUKA,人材紹介"
              />
              <figcaption class="home-team-number">
                <h1><span  class="purecounter">96</span><sup>+</sup></h1>
                <p>クライアント実績</p>
              </figcaption>
            </figure>
          </div>
        </b-col>

        <b-col
          md="4"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="500"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="home-teams-member">
            <figure>
              <img
                :style="memberImgStyle"
                src="../assets/customer.png"
                class="teams-icon"
                alt="株式会社ASUKA,人材紹介"
              />
              <figcaption class="home-team-number">
                <h1><span class="purecounter1">1068</span><sup>+</sup></h1>
                <p>紹介実績</p>
              </figcaption>
            </figure>
          </div>
        </b-col>
        <b-col
          md="4"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="500"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="home-teams-member">
            <figure>
              <img
                :style="memberImgStyle"
                src="../assets/customer.png"
                class="teams-icon"
                alt="株式会社ASUKA,人材紹介"
              />
              <figcaption class="home-team-number">
                <h1><span class="purecounter2">69</span><sup>+</sup></h1>
                <p>今年度実績</p>
              </figcaption>
            </figure>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-img src="../assets/japan.svg">

          </b-img>
        </b-col>
      </b-row> -->
    </b-container>
  </section>
</template>

<script>
import PureCounter from "@srexi/purecounterjs";
export default {
  name: "home-teams",
  data() {
    return {
      memberImgStyle: {
        blank: "true",
        blankColor: "#777",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        marginBottom: "15px",
        border: "none",
      },
    };
  },
  mounted() {
    new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter", // HTML query selector for spesific element

      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 96, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: true, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 0, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
    });
    new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter1", // HTML query selector for spesific element

      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 1068, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: true, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 0, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
    });
    new PureCounter({
      // Setting that can't' be overriden on pre-element
      selector: ".purecounter2", // HTML query selector for spesific element

      // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
      start: 0, // Starting number [uint]
      end: 69, // End number [uint]
      duration: 2, // The time in seconds for the animation to complete [seconds]
      delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
      once: true, // Counting at once or recount when the element in view [boolean]
      pulse: false, // Repeat count for certain time [boolean:false|seconds]
      decimals: 0, // How many decimal places to show. [uint]
      legacy: true, // If this is true it will use the scroll event listener on browsers
      filesizing: false, // This will enable/disable File Size format [boolean]
      currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
      formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
      separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
    });
  },
};
</script>

<style scoped>
#home-teams {
  /* background: #e9ecef;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  /* background-image: url("../assets/asuka_bg.png");
  background-size: cover; */

  padding: 50px 0;
  font-size: 1.1rem;
  margin-top: 10%;
}
.text-white-space {
  white-space: pre;
}
.home-team-number {
  margin-top: 50px;
}
.home-team-number h1 {
  font-weight: bolder;
  font-size: 3rem;
  color: rgb(223, 167, 14) ;
}
.home-team-number p {
  font-weight: bold;
  font-size: 1.3rem;

}
@media (min-width: 2400px) {
  .team-container {
    max-width: 1480px;
  }
}
@media (min-width: 768px) {
  .text-introduce {
    width: 90%;
    margin: auto;
  }
}
.home-teams-member {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  cursor: pointer;
  background: #697991;
  margin-top: 50px;
  margin-bottom: 140px;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.home-teams-member:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, #111c55 0px 10px 10px;
  transform: translateY(-0.25em);
  transition: 0.3s all ease-in-out;
}
.home-teams-member figure img {
  /* margin-top: 15px; */
}

.home-member-position {
  margin-bottom: 10px;
}
.count-txt {
  color: #fff;
  font-weight: bold;
  margin-bottom: 2px !important;
  font-size: 48px;
  line-height: 48px;
  display: block;
  font-weight: 700;
}
.count-item {
  color: #fff;
  font-size: 18;
  font-weight: bold;
}
.teams-icon {
  /* margin-bottom: 5px !important;
  border: 3px solid; */
  width: 200px !important;
  height: 200px !important;
  margin: auto;
  position: relative;
  object-fit: cover;
}
</style>