<template>
  <b-container id="overview" fluid>
    <b-row class="flex-column">
      <b-col md="12" class="mx-auto overview-head">
        <figure>
          <img
            src="@/assets/asuka-building.png"
            alt="株式会社ASUKA"
            style="width: 100%"
          />
          <!-- <img src="@/assets/img_main.jpg" alt="Trulli" style="width:100%"> -->
          <!-- <figcaption>Fig.1 - Trulli, Puglia, Italy.</figcaption> -->
        </figure>
        <div class="header-vision">
          <h1>VISION</h1>
          <h3 class="mt-4">「総合人材サービス国際的なキャリアの架け橋」</h3>
          <p class="head-vision-introduce">
            {{ $t("header_vision_introduce") }}
          </p>
        </div>
      </b-col>
      <b-col
        md="12"
        class="cooprate-vision p-3"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="false"
        data-aos-once="true"
        data-aos-anchor-placement="bottom"
      >
        <h1 class="mb-2 access-label">VISION</h1>
        <p>ビジョン</p>
        <p class="mission-intro-1 mission-intro">
          「総合人材サービス国際的なキャリアの架け橋」
        </p>
        <p class="mission-intro">
          ASUKAは、外国人労働者と日本企業との間の
          信頼性の高いリーダーとして認識されることを 目指しています。
          <br />
          私たちは、日本の多様経済環境において、可能な成長と発展に貢献します。
        </p>
      </b-col>
      <b-col md="12" class="w-100">
        <div
          class="vision-intr-2 w-100"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="bottom"
        >
          <div class="vision-intr-2-block1">
            <div class="vision-intr-2-left">
              <p class="vision-intr-txt">
                外国人は日本で稼ぐだけのことではなく、<br />これから生活は日本です。<br />日本企業は海外人材を雇うことだけもない、<br />これから日本経済に外国人の力が不可欠のものです。<br />是非、同行で成長していきたいと思っております。
              </p>
              <p class="director-txt">代表取締役 &nbsp;&nbsp;&nbsp;チュン&nbsp;アン&nbsp;ゴック</p>
              <!-- <b-img src="@/assets/vision-blue-bg.png"></b-img> -->
            </div>
          </div>
          <div class="vision-intr-2-block2">
            <img
              class="vision-intr-2-right"
              src="@/assets/asuka-vision-img1.png"
              width="60%"
            />
            <!-- <b-img src="@/assets/vision-blue-bg.png"></b-img> -->
          </div>
        </div>
      </b-col>
      <b-col
        md="12"
        class="cooprate-mission p-3"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="false"
        data-aos-once="true"
        data-aos-anchor-placement="bottom"
      >
        <h1 class="mb-2 access-label">MISSION</h1>
        <p>使命</p>
        <p class="mission-intro-1 mission-intro text-bold">
          日本企業と外国人労働者が効果的に連携し、
        </p>
        <p class="mission-intro text-bold">
          成長と成功を達成することをサポートすることです。
        </p>
        <p class="mission-intro text-bold">国際的な多様性を尊重し、双方にとって</p>
        <p class="mission-intro text-bold">有益なパートナーシップを促進します。</p>
      </b-col>
      <b-col
        md="12"
        class="cooprate-info-tbl mx-auto p-3"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="false"
        data-aos-once="true"
        data-aos-anchor-placement="bottom"
      >
        <h1 class="mb-2 access-label">COMPANY</h1>
        <p>会社概要</p>
        <table class="company-info-tbl mx-auto">
          <tr class="company-info-row">
            <td class="label-left">会社名</td>
            <td class="label-right">株式会社ASUKA</td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">設立</td>
            <td class="label-right">2018年06月</td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">資本金</td>
            <td class="label-right">20,000,000</td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">代表者</td>
            <td class="label-right">代表取締役 チュン アン ゴック</td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">従業員</td>
            <td class="label-right">32名(2023 年 6月現在)</td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">事業内容</td>
            <td class="label-right">
              <ul class="p-0 tbl-ul-li">
                <li>有料職業紹介・労働者派遣</li>
                <li>特定技能登録支援機関</li>
                <li>各種ビザ申請サポート</li>
                <li>飲食事業</li>
              </ul>
            </td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">主要取引先</td>
            <td class="label-right">
              <ul class="p-0 tbl-ul-li">
                <li>日本政策金融公庫</li>
                <li>三菱 UFJ 銀行・愛知銀行</li>
                <li>リンケージ株式会社</li>
                <li>八幡金属株式会社</li>
                <li>轟鐵工株式会社</li>
                <li>株式会社トピアネクステージ</li>
              </ul>
            </td>
          </tr>
          <tr class="company-info-row">
            <td class="label-left">許可証</td>
            <td class="label-right">
              <ul class="p-0 tbl-ul-li">
                <li>有料職業紹介事業 <br />許可番号 23- ユ -302053</li>
                <li>登録支援機関 <br />許可番号 19 登 -000755</li>
                <li>労働者派遣事業<br />許可番号 派 23-303854</li>
              </ul>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        class="w-100 vision-access"
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="bottom"
      >
        <h1 class="access-label">ACCESS</h1>
        <p>アクセス</p>
        <p class="access-intro access-intro-1">
          愛知県名古屋市港区西川通4-9-2ASUKAビル3F
        </p>
        <p>TEL:052-977-9445 FAX:052-977-4660</p>
        <p>港北駅・・・走行3分</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52202.51248507835!2d136.8521914347273!3d35.14023141705789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60037940ce2ff063%3A0xd42049b2ba49bff7!2z5qCq5byP5Lya56S-QVNVS0E!5e0!3m2!1svi!2sjp!4v1698154232068!5m2!1svi!2sjp"
          width="100%"
          height="400"
          style="border: 0"
          class="access-map"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions(["setTab"])
  },
  mounted() {
    this.setTab(2)
  }
};
</script>

<style scoped>
#overview {
  padding: 0 10px 100px 10px;
  overflow: hidden;
  color: #000;
  font-size: 1.1rem;
}
.cooprate-info-tbl {
  margin-top: 10%;
}
.director-txt {
  font-weight: bold;
  font-size: 1.1rem;
}
.overview-head {
  width: 100%;
  /* height: 100vh; */
}
.cooprate-mission {
  margin-top: 10%;
}
.vision-intr-txt-ab {
  font-weight: bold;
  text-wrap: nowrap;
}
.vision-access {
  margin-top: 10%;
}
.access-intro-1,
.access-map {
  margin-top: 3%;
}
.access-map {
  filter: grayscale(1);
  width: 100%;
}
.company-info-tbl {
  width: 100%;
  margin-top: 5%;
  background-image: url("../assets/asuka_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.company-info-tbl tr td {
  border: none;
  /* border-bottom: 1px solid; */
  padding: 20px;
}
.label-left {
  font-size: 18px;
  font-weight: bold;
  width: 50%;
  text-align: right;
  padding-right: 5% !important;
}
.label-right {
  text-align: left;
}
.img-content {
  position: relative;
}
.access-label {
  width: 200px;
  margin: auto;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.header-vision {
  position: absolute;
  top: 25%;
  left: 7%;
  color: #fff;
}
.header-vision h1 {
  font-weight: bolder;
  letter-spacing: 0.5rem;
  font-size: 4rem;
  font-family: Arial, sans-serif;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomIn";
  animation-delay: 0.2s;
}
.header-vision h3 {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 1s;
}
.head-vision-introduce {
  margin-top: 10%;
  white-space: pre;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 1.5s;
}
.vision-intr-2 {
  height: 350px;
  margin-top: 12%;
  background: #eef1f6;
  display: inline-block;
}
.vision-intr-2-block1 {
  width: 50%;
  float: left;
  height: 100%;
  position: relative;
}
.vision-intr-2-block2 {
  width: 50%;
  float: right;
  height: 100%;
  position: relative;
}
.vision-intr-2-left {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: fit-content;
  text-align: left;
}
.vision-intr-2-right {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75px);
}
.vision-intr-txt {
  margin-top: 20px;
  white-space: pre;
}
.tbl-ul-li li {
  list-style: none;
}
.cooprate-vision {
  display: none;
}
.text-bold {
  font-weight: bold;
}
@media (min-width: 2400px) {
  #overview {
    font-size: 1.3rem !important;
  }
}
@media (min-width: 1701px) {
  .vision-intr-2-left {
    left: 75%;
  }
  .vision-intr-2-right {
    left: 40%;
    width: 50%;
    max-width: 710px;
  }
}

@media (max-width: 1700px) {
  .header-vision {
    left: 5%;
    top: 20%;
  }
  .header-vision h1 {
    font-weight: bolder;
    letter-spacing: 0.1em;
    font-size: 3rem;
    font-family: Arial, sans-serif;
  }
  .vision-intr-2-right {
    width: 70%;
  }
  .vision-intr-2 {
    height: 300px;
  }
}

.mission-intro-1 {
  margin-top: 5%;
}
@media (max-width: 1100px) {
  .vision-intr-2-right {
    width: 85%;
  }
}
@media (max-width: 992px) {
  .header-vision {
    top: 5%;
    left: 5%;
    width: 90%;
    background: #27201991;
    color: #fcfcfd;
  }
  .header-vision h1 {
    font-size: 2rem;
  }
  .head-vision-introduce {
    margin-top: 5% !important;
  }
  .header-vision h3 {
    font-size: 1.1em !important;
  }
  .vision-intr-2 {
    height: 350px;
  }
  .vision-intr-2-left {
    position: relative;
    top: 0;
    left: 0;
    transform: unset;
    margin: auto;
  }
  .vision-intr-2-right {
    position: relative;
    top: 0;
    left: 0;
    transform: unset;
    margin: auto;
  }
  .vision-intr-2-right {
    width: 95%;
  }
  .vision-intr-2-block2,
  .vision-intr-2-block1 {
    display: flex;
  }
  .vision-intr-2-left h1 {
    font-size: 1.5em;
  }
  .vision-intr-txt {
    white-space: unset;
  }
}
@media (max-width: 768px) {
  .header-vision {
    top: 5%;
  }
  .vision-intr-2-block2,
  .vision-intr-2-block1 {
    width: 100%;
  }
  .vision-intr-2 {
    height: fit-content;
    padding: 50px 10px;
  }
  .header-vision {
    display: none;
  }
  .cooprate-vision {
    display: block;
  }
}

@media (max-width: 576px) {
  .header-vision h3 {
    font-size: 1.1em !important;
  }
  .header-vision h1 {
    font-size: 1.9em !important;
  }
  .head-vision-introduce {
    white-space: unset;
  }
  .label-left {
    width: 40%;
  }
  #overview h1 {
    font-size: 1.5rem;
  }
  .vision-intr-2-left{
    text-align: left;
  }
  .mission-intro {
    font-weight: unset;
    text-align: left;
  }
}
</style>