<template>
  <b-container class="engineer-info" fluid>
    <b-row class="flex-column">
      <b-col class="pl-5 back-col">
        <div class="back-area" @click="handleBack">
          <font-awesome-icon
            class="back-btn"
            icon="fa-solid fa-circle-left"
          />
        </div>
      </b-col>
      <b-col class="pl-5 mt-5 title-col">
        <h1 id="engneer-title" class="ml-3 font-weight-bolder text-left">
          技術・人文知識・国際業務
        </h1>
      </b-col>
    </b-row>
    <b-row class="flex-row">
      <b-col
        md="6"
        class="engneer-left pl-5"
        data-aos="fade-right"
        data-aos-delay="200"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="engineer-category">
          <p>
            <font-awesome-icon icon="fa-solid fa-circle" />
            理系:製造加工、設計、機械操作、機械設備の保守・保全、研究、開発、etc
          </p>
          <p>
            <font-awesome-icon icon="fa-solid fa-circle" /> 文系 :
            オフィスワーク系職種、通訳、翻訳、接客販売、etc
          </p>
        </div>
        <img
          src="@/assets/engineer-img1.png"
          width="100%"
          class="engineer-img1"
          alt="株式会社ASUKA、人材紹介"
        />
        <div class="w-100">
          <img
            src="@/assets/engineer-img2.png"
            class="engineer-img2"
            width="50%"
            alt="株式会社ASUKA、人材紹介"
          />
          <!-- <img
            src="@/assets/engineer-img3.png"
            class="engineer-img2"
            width="50%"
            alt="株式会社ASUKA、人材紹介"
          /> -->
        </div>
      </b-col>
      <b-col
        md="6"
        data-aos="fade-left"
        data-aos-delay="500"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="engneer-right pr-5">
          <p class="engineer-condition">在留資格の付与要件</p>
          <div class="condition-txt1 text-left mt-5">
            <p>
              <font-awesome-icon icon="fa-solid fa-circle" />
              雇用企業・受入れ機関
            </p>
            <div class="condition-sub-content">
              <p>① 外国人と結ぶ雇用契約が適切</p>
              <ul>
                <li>報酬額が日本人と同等以上</li>
                <li>雇用契約が継続的なものであること</li>
              </ul>
              <p>② 理系 / 文系の専門分野に属する 技術または</p>
              <p>知識を有する業務に従事する活動であること</p>
            </div>
          </div>
          <div class="condition-txt1 text-left mt-5">
            <p><font-awesome-icon icon="fa-solid fa-circle" /> 労働者</p>
            <div class="condition-sub-content">
              <p>従事しようとする業務として、これに必要な</p>
              <p>技術並びに知識に係る科目を専攻し、大学を</p>
              <p>卒業し、</p>
              <p>もしくは、それと同等以上の教育を受け、</p>
              <p>または 10 年以上の業務経験を有する</p>
            </div>
          </div>
          <div class="condition-txt2 text-left mt-5">
            <p class="engineer-condition text-center">受入流れ</p>
            <img
              src="@/assets/engineer-img3.png"
              class="engineer-img3"
              width="85%"
              alt="株式会社ASUKA、人材紹介"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    handleBack() {
      this.$router.back();
    },
    ...mapActions(["setTab"]),
  },
  mounted() {
    this.setTab(0);
  },
};
</script>

<style scoped>
.engineer-info {
  background-image: url("../assets/asuka_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10px 100px 10px;
  overflow: hidden;
  font-size: 1.1rem;
}
.back-area {
  float: left;
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
  /* margin-top: 10px; */
  position: fixed;
  left: 10px;
  top:80px;
  z-index: 1000;
}
.back-area span {
  font-size: 20px;
  display: flex;
  align-content: center;
  font-weight: bold;
  text-decoration: underline;
}
.back-btn {
  font-size: 40px;
}
.engineer-category {
  position: relative;
  background: #292a54;
  border-radius: 19px;
  padding: 30px;
  width: 80%;
  margin-left: 30%;
  filter: brightness(120%);
  font-size: 17px;
  color: #fff;
  margin: auto;
  text-align: left;
  margin-top: 80px;
}
.engineer-img1 {
  position: relative;
  margin-top: 50px;
  width: 80%;
}
.engineer-condition {
  background: #292a54;
  position: relative;
  border-radius: 9px;
  padding: 13px;
  width: 80%;
  color: #fff;
  margin: auto;

  font-weight: bolder;
  font-size: 22px;
  margin-top: 80px;
}
.condition-txt1 {
  position: relative;
  width: 80%;
  left: 10%;
}
.condition-txt2 {
  width: 100%;
}
.condition-sub-content {
  margin-left: 5% !important;
}

@media (max-width: 768px) {
  .engineer-img2 {
    width: 100%;
  }
  .engineer-img3 {
    width: 100%;
  }
  .back-col {
    padding-left: unset !important;
  }
  .title-col {
    padding-left: unset !important;
    text-align: center;
  }
  #engneer-title {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .engineer-img2 {
    width: 60%;
  }
  .engineer-img3 {
    width: 60%;
    position: relative;
    left: 20%;
  }
}
@media screen and (max-width: 576px) {
  #engneer-title {
    font-size:1.5rem!important;
    margin-top: 40px;
  }
}
@media screen and (max-width: 500px) {
  .engneer-left,
  .engneer-right {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}
</style>