<template>
  <b-container fluid id="foreign-info" class="about">
    <b-row class="flex-column">
      <b-col class="pl-5 back-col">
        <div class="back-area" @click="handleBack">
          <font-awesome-icon class="back-btn" icon="fa-solid fa-circle-left" />
        </div>
      </b-col>
      <b-col class="pl-5 mt-5">
        <h1 id="foreign-title">外国人滞在情報</h1>
      </b-col>
    </b-row>
    <b-row class="flex-row mt-3">
      <b-col
        md="6"
        class="foregin-left pl-5 pr-5"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <p class="text-left foreign-left-intro">{{ $t("foreign_txt_1") }}</p>
        <div class="foreign-tbl d-flex flex-column flex-nowarp text-left">
          <p>2022年6現在</p>
          <h4 class="now-data-title">国籍・地域別</h4>
          <table>
            <tr v-for="(val, index) in peopleByCountry" :key="index">
              <template v-if="val.no == 2 || val.no == 5 || val.no == 7">
                <td class="pl-2 text-danger">({{ val.no }})</td>
                <td class="text-danger tbl-td2">{{ val.country }}</td>
                <td class="text-danger">{{ val.people }}人</td>
                <td class="text-danger">({{ val.compare }})</td>
                <td class="text-danger pr-2">({{ val.increase }})</td>
              </template>
              <template v-else>
                <td class="pl-2">({{ val.no }})</td>
                <td class="tbl-td2">{{ val.country }}</td>
                <td>{{ val.people }}人</td>
                <td>({{ val.compare }})</td>
                <td>({{ val.increase }})</td>
              </template>
            </tr>
          </table>
          <h4 class="now-data-title">在留資格別</h4>
          <table>
            <tr v-for="(val, index) in peopleByCertificate" :key="index">
              <template v-if="val.no == 2 || val.no == 5 || val.no == 3">
                <td class="pl-2 text-danger">({{ val.no }})</td>
                <td class="text-danger tbl-td2">{{ val.country }}</td>
                <td class="text-danger">{{ val.people }}人</td>
                <td class="text-danger">({{ val.compare }})</td>
                <td class="pr-2 text-danger">({{ val.increase }})</td>
              </template>
              <template v-else>
                <td class="pl-2">({{ val.no }})</td>
                <td class="tbl-td2">{{ val.country }}</td>
                <td>{{ val.people }}人</td>
                <td>({{ val.compare }})</td>
                <td class="pr-2">({{ val.increase }})</td>
              </template>
            </tr>
          </table>
          <h4 class="now-data-title">都道府県別</h4>
          <table>
            <tr v-for="(val, index) in peopleByWard" :key="index">
              <template v-if="val.no == 2 || val.no == 3">
                <td class="pl-2 text-danger">({{ val.no }})</td>
                <td class="text-danger tbl-td2">{{ val.country }}</td>
                <td class="text-danger">{{ val.people }}人</td>
                <td class="text-danger">({{ val.compare }})</td>
                <td class="text-danger pr-2">({{ val.increase }})</td>
              </template>
              <template v-else>
                <td class="pl-2">({{ val.no }})</td>
                <td class="tbl-td2">{{ val.country }}</td>
                <td>{{ val.people }}人</td>
                <td>({{ val.compare }})</td>
                <td>({{ val.increase }})</td>
              </template>
            </tr>
          </table>
          <div class="mt-5">
            <b-img
              class="foreign-image1"
              src="@/assets/foreign-image1.png"
            ></b-img>
            <!-- <p class="foreign-img-text">
              コロナ前から小子高齢化にする人手不足が続く、若い労働力が必要な領域の人材が確保できないという課題が引き続き残っています。そうした人材不足の課題に対して、外国人の採用に様々な企業様が検討されています。
            </p> -->
          </div>
        </div>
      </b-col>
      <b-col
        md="6"
        class="foreign-right pl-5 pr-5"
        data-aos="fade-down"
        data-aos-delay="300"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <p class="foreign-right-text1">
          外国人採用に関しては「どんな手続きが必要か」や「日本語のコミュニケーションがとれるか？」といった漠然とした不安を持っている企業が多いです。そうした悩みを解決するため、弊社株式会社ASUKAは全力でサポートさせていただきます。
        </p>
        <div class="foreign-merit-parent">
          <div class="foreign-merit">
            <b-img fluid src="@/assets/merit.png"></b-img>
          </div>
          <div class="foreign-meti-content">
            <div class="d-flex flex-row flex-nowrap align-items-center mb-3">
              <img
                src="@/assets/number-circle-2.png"
                width="40px"
                alt="株式会社ASUKA、人材紹介"
              /><span class="content ml-4"
                >就業意欲が高い（＝定着性が良い）</span
              >
            </div>
            <div class="d-flex flex-row flex-nowrap align-items-center mb-3">
              <img
                src="@/assets/number-circle-1.png"
                width="40px"
                alt="株式会社ASUKA、人材紹介"
              /><span class="content ml-4">年層の労働力確保</span>
            </div>
            <div class="d-flex flex-row flex-nowrap align-items-center mb-3">
              <img
                src="@/assets/number-circle-3.png"
                width="40px"
                alt="株式会社ASUKA、人材紹介"
              /><span class="content ml-4"
                >日本語でコミュニケーションが取れます</span
              >
            </div>
            <div class="d-flex flex-row flex-nowrap align-items-center mb-3">
              <img
                src="@/assets/number-circle-4.png"
                width="40px"
                alt="株式会社ASUKA、人材紹介"
              /><span class="content ml-4"
                >各職種の知識を得た人材(=仕事の慣れるのは早い)</span
              >
            </div>
            <div class="d-flex flex-row flex-nowrap align-items-center">
              <img
                src="@/assets/number-circle-5.png"
                width="40px"
                alt="株式会社ASUKA、人材紹介"
              /><span class="content ml-4"
                >現地に詳しい人材の登用可能(=海外へ進出時に役立つ)</span
              >
            </div>
          </div>
        </div>
        <div class="foreign-demerit-parent">
          <div class="foreign-demerit">
            <b-img fluid src="@/assets/demerit.png"></b-img>
          </div>
          <div class="foreign-demerit-content text-light text-left">
            デメリットとしては、在留資格の付与・変更するのに期間を要しますので、一般の採用に比べて期間が必要です。しかしながら、慢性的な人材不足の問題を抱えられている企業様にとっては、<strong
              class="text-danger"
              >『デメリット・メリット』</strong
            >の効果を期待できます。
          </div>
        </div>
        <div class="foreign-matome-parent">
          <div class="foreign-matome">
            <b-img fluid src="@/assets/matome.png"></b-img>
          </div>
          <div class="foreign-matome-content text-light text-left">
            従来は日本人の採用が当然のこと、採用を行っておりました。しかし、昨今の人材不足が続く中、現在は<strong
              class="text-danger"
              >『待ったなし』</strong
            >の状況です。これを機会に<strong class="text-danger"
              >『若くて、就業意欲の高い』</strong
            >
            外国籍スタッフを活用しませんか？ 弊社が全力で支援させて頂きます。
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- <div class="foreign-bg">
        <b-img  src="../assets/asuka_bg.png" fluid></b-img>
      </div> -->
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      peopleByCountry: [
        {
          no: 1,
          country: "中国",
          people: "744,551",
          compare: "構成比25.1%",
          increase: "+3.9%",
        },
        {
          no: 2,
          country: "ベトナム",
          people: "476,346",
          compare: "構成比16.1%",
          increase: "+10.0%",
        },
        {
          no: 3,
          country: "韓国",
          people: "412,340",
          compare: "構成比13.9%",
          increase: "+0.6%",
        },
        {
          no: 4,
          country: "フィリピン",
          people: "291,066",
          compare: "構成比9.8%",
          increase: "+5.2%",
        },
        {
          no: 5,
          country: "ブラジル",
          people: "207,081",
          compare: "構成比7.0%",
          increase: "+1.1%",
        },
        {
          no: 6,
          country: "ネパール",
          people: "125,798",
          compare: "構成比4.2%",
          increase: "+29.5%",
        },
        {
          no: 7,
          country: "インドネシア",
          people: "83,169",
          compare: "構成比2.8%",
          increase: "+39.0%",
        },
        {
          no: 8,
          country: "米国",
          people: "57,299",
          compare: "構成比1.9%",
          increase: "+5.8%",
        },
        {
          no: 9,
          country: "タイ",
          people: "54,618",
          compare: "構成比1.8%",
          increase: "+8.5%",
        },
        {
          no: 10,
          country: "台湾",
          people: "54,213",
          compare: "構成比1.8%",
          increase: "+5.9%",
        },
      ],
      peopleByCertificate: [
        {
          no: 1,
          country: "永住者",
          people: "845,693",
          compare: "構成比28.6%",
          increase: "+1.7%",
        },
        {
          no: 2,
          country: "技能実習",
          people: "327,689",
          compare: "構成比11.1%",
          increase: "+18.7%",
        },
        {
          no: 3,
          country: "技術・人文知識・国際業務",
          people: "300,045",
          compare: "構成比10.1%",
          increase: "+9.2%",
        },
        {
          no: 4,
          country: "特別永住者",
          people: "292,702",
          compare: "構成比9.9%",
          increase: "-1.3%",
        },
        {
          no: 5,
          country: "留学",
          people: "260,767",
          compare: "構成比8.8%",
          increase: "+25.5%",
        },
      ],
      peopleByWard: [
        {
          no: 1,
          country: "東京部",
          people: "566,525",
          compare: "構成比19.1%",
          increase: "+6.7%",
        },
        {
          no: 2,
          country: "愛知県",
          people: "280,912",
          compare: "構成比9.5%",
          increase: "+5.9%",
        },
        {
          no: 3,
          country: "大阪府",
          people: "262,681",
          compare: "構成比8.9%",
          increase: "+6.7%",
        },
        {
          no: 4,
          country: "神奈川県",
          people: "237,450",
          compare: "構成比8.0%",
          increase: "+4.4%",
        },
        {
          no: 5,
          country: "埼玉県",
          people: "205,824",
          compare: "構成比6.9%",
          increase: "+4.4%",
        },
      ],
    };
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
    ...mapActions(["setTab"]),
  },
  mounted() {
    this.setTab(0);
  },
};
</script>

<style scoped>
#foreign-info {
  background-image: url("../assets/asuka_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 10px 100px 10px;
  overflow: hidden;
  font-size: 1.1rem;
  /* width: 100%;
  height: 100vh; */
}
.back-area {
  float: left;
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
  position: fixed;
  left: 10px;
  top: 80px;
  z-index: 1000;
}
.back-area span {
  font-size: 20px;
  display: flex;
  align-content: center;
  font-weight: bold;
  text-decoration: underline;
}
.back-btn {
  font-size: 40px;
}
#foreign-title {
  /* color: #fff; */
  font-weight: bolder;
  text-align: left;
}
.foreign-tbl {
  width: 90%;
  margin: auto;
}
.foreign-bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.now-data-title {
  background: #292a54;
  padding: 10px;
  color: #fff;
  border-radius: 9px;
  font-size: 1.2rem;
}

.foreign-image1 {
  width: 100%;
  filter: brightness(90%);
  border-radius: 4px;
}
.foreign-img-text {
  position: absolute;
  bottom: 10px;
  color: #fff;
  padding-left: 5px;
  padding-right: 10px;
  width: 75%;
}
.foreign-merit,
.foreign-demerit {
  width: 250px;
  /* clip-path: polygon(100% 0%,100% 51%,82% 52%,36% 53%,0% 53%,25% 0%);
  background: #d34812; */
  position: absolute;
  right: 0%;
  top: 0;
}
.foreign-matome {
  width: 250px;
  top: 0;
  left: 4%;
  position: absolute;
}
.foreign-merit-parent,
.foreign-demerit-parent,
.foreign-matome-parent {
  position: relative;
}
.foreign-demerit-parent {
  margin-top: 120px;
}
.foreign-matome-parent {
  margin-top: 120px;
}
.circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #fff;
  /* border: 3px solid #000; */
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}
.foreign-meti-content {
  position: relative;
  top: 70px;
  background: #212a41;
  border-radius: 19px;
  padding: 30px;
  width: 86%;
  margin-left: 10%;
  filter: brightness(120%);
}
.foreign-demerit-content {
  position: relative;
  top: 70px;
  background: #212a41;
  border-radius: 19px;
  padding: 30px;
  width: 60%;
  margin-left: 30%;
  filter: brightness(120%);
  font-size: 17px;
}
.foreign-matome-content {
  position: relative;
  top: 85px;
  background: #212a41;
  border-radius: 19px;
  padding: 30px;
  width: 90%;
  left: 5%;
  filter: brightness(120%);
  font-size: 17px;
}
.foreign-meti-content .content {
  font-size: 18px;
  color: #fff;
}
.foreign-right-text1 {
  width: 90%;
  left: 10%;
  position: relative;
  text-align: left;
}
.tbl-td2 {
  width: 30%;
}
@media (max-width: 768px) {
  .foreign-demerit-content,
  .foreign-matome-content,
  .foreign-meti-content {
    margin-left: 0 !important;
    width: 100% !important;
    left: unset;
    font-size: 1rem;
  }
  .foreign-meti-content .content {
    font-size: 1rem;
  }
  .foreign-tbl {
    width: 100%;
  }
  .foreign-right {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .foreign-right-text1 {
    width: 100% !important;
    left: unset;
  }
  .back-col {
    padding-left: unset !important;
  }
}
@media (max-width: 576px) {
  #foreign-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .tbl-td2 {
    width: unset;
    max-width: 50px;
  }
  .foregin-left,
  .foregin-right {
    padding-left: 7px !important ;
    padding-right: 7px !important;
  }
  .foreign-right {
    padding-left: 15px !important ;
    padding-right: 15px !important;
  }
  .foreign-left-intro {
    padding-left: 10px !important ;
    padding-right: 10px !important;
  }
  .foregin-left :deep(table tr td) {
    font-size: 1rem;
    padding: 5px 0 5px;
  }
  .foreign-right-text1 {
    width: 100% !important;
  }
}
</style>