<template>
  <b-container class="home-certificate-sub">
    <b-row>
      <b-col>
        <div class="cert-sub">
          <p class="company-txt">株式会社 ASUKA</p>
          <h4 class="font-weight-bold">登録支援機関</h4>
          <div class="cert-number-sub">
            <!-- <p>許可番号</p> -->
            <p>19登-000755</p>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="cert-sub">
          <p class="company-txt">株式会社 ASUKA</p>
          <h4 class="font-weight-bold">有料職業紹介事業</h4>
          <div class="cert-number-sub">
            <!-- <p>許可番号</p> -->
            <p>23- ユ -302053</p>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="cert-sub">
          <p class="company-txt">株式会社 ASUKA</p>
          <h4 class="font-weight-bold">労働者派遣事業</h4>
          <div class="cert-number-sub">
            <!-- <p>許可番号</p> -->
            <p>派 23-303854</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style scoped>
.home-certificate-sub {
    margin-top: 10%;
    display: none
}
.home-certificate-sub .cert-sub {
    border-radius: unset;
}
.cert-sub {
  background: #111c55bd;
  color: #fcfcfd;
  /* border: 2px  solid; */
  white-space: nowrap;
  padding: 5px;
  margin: 5px;
}
.cert-number-sub p {
  border: 1px solid;
  padding: 5px;
}
@media (max-width: 991px) {
    .home-certificate-sub {
        display: block;
    }
}
</style>