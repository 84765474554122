<template>
  <header id="conent">
    <b-container class="pl-0 pr-0" fluid>
      <b-row>
        <b-col class="w-100">
          <b-carousel
            id="home-carousel"
            v-model="slide"
            :interval="5000"
            :controls="false"
            :indicators="false"
            background="#ababab"
            img-width="1024"
            img-height="480"
            ref="myCarousel"
            style="text-shadow: 1px 1px 2px #333"
          >
            <div id="home-slide-prev-div">
              <font-awesome-icon
                @click="handlePrevSlide"
                id="home-slide-prev-btn"
                icon="fa-solid fa-angle-left"
              />
            </div>
            <div id="home-slide-next-div">
              <font-awesome-icon
                @click="handleNextSlide"
                id="home-slide-next-btn"
                icon="fa-solid fa-angle-right"
              />
            </div>

            <template><div class="img-caption-hide"></div></template>
            <!-- Text slides with image -->
            <b-carousel-slide>
              <template v-slot:img>
                <img
                  class="img-slide"
                  width="100%"
                  src="../assets/home-slide-1.png"
                  alt="株式会社ASUKA,人材紹介"
                />
              </template>
              <!-- <h3>ようこそ <span>ASUKA</span></h3>
        <p>仕事を探す外国の方へ</p> -->
              <carousel
                :autoplay="false"
                :loop="true"
                :smartSpeed="700"
                :items="1"
                :nav="false"
                :dots="false"
                class="slide-content"
              >
                <div class="header-content">
                  <div class="line"></div>
                  <h3><span>総合人材サービス国際的なキャリアの架け橋</span></h3>
                  <h2>外国人労働者のキャリアサポート</h2>
                </div>
              </carousel>
            </b-carousel-slide>

            <!-- Slides with custom text -->
            <b-carousel-slide>
              <template v-slot:img>
                <img
                  class="img-slide"
                  width="100%"
                  src="../assets/home-slide-2.png"
                  alt="株式会社ASUKA,人材紹介"
                />
              </template>
              <p class="slogan-slide-2">国際共同事業パートナー</p>
              <h3 class="slogan-slide-2">
                <span>グローバルな力</span>国際的な成功をサポート
              </h3>
            </b-carousel-slide>
            <div class="home-certificate" v-show="currentIndex == 0">
              <div class="cert-1">
                <p class="company-txt">株式会社 ASUKA</p>
                <h4 class="font-weight-bold">登録支援機関</h4>
                <div class="cert-number">
                  <p>許可番号</p>
                  <p>19登-000755</p>
                </div>
              </div>
              <div class="cert-1">
                <p class="company-txt">株式会社 ASUKA</p>
                <h4 class="font-weight-bold">有料職業紹介事業</h4>
                <div class="cert-number">
                  <p>許可番号</p>
                  <p>23- ユ -302053</p>
                </div>
              </div>
              <div class="cert-1">
                <p class="company-txt">株式会社 ASUKA</p>
                <h4 class="font-weight-bold">労働者派遣事業</h4>
                <div class="cert-number">
                  <p>許可番号</p>
                  <p>派 23-303854</p>
                </div>
              </div>
            </div>
            <div class="home-purpose" v-show="currentIndex == 0">
              <!-- <p class="text-left text-quote">
                <font-awesome-icon icon="fa-solid fa-quote-left" />
              </p> -->
              <p class="home-purose-title font-weight-bold">
                人材確保でお困り企業様へ
              </p>
              <p class="purpose-txt">
                {{ $t("header_sloan_right") }}
              </p>
            </div>
            <div class="home-purpose" v-show="currentIndex == 1">
              <!-- <p class="text-left text-quote">
                <font-awesome-icon icon="fa-solid fa-quote-left" />
              </p> -->
              <p class="home-purose-title font-weight-bold">
                海外へ進出相談窓口
              </p>
              <p class="purpose-txt">
                {{ $t("header_sloan_right2") }}
              </p>
            </div>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "home-header",
  components: { carousel },
  data() {
    return {
      slide: 0,
    };
  },
  computed: {
    currentIndex() {
      return this.slide;
    },
  },
  methods: {
    handlePrevSlide() {
      this.$refs.myCarousel.prev();
    },
    handleNextSlide() {
      this.$refs.myCarousel.next();
    },
  },
};
</script>

<style>
#conent {
  width: 100%;
  height: 85vh;
  /* font-size: 1.1rem; */
}
.img-slide {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}
#home-carousel {
  width: 100%;
  height: 100%;
}

#home-carousel .carousel-inner .carousel-item .carousel-caption {
  z-index: 1001 !important;
  bottom: 35% !important;
  top: 32% !important;
  color: #f8f9fa;
}
#home-slide-prev-btn,
#home-slide-next-btn {
  border: 2px solid rgba(100, 100, 100, 0.9);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #ebebe5;
  font-size: 35px;
  border-radius: 50%;
  background: rgba(100, 100, 100, 0.9);
  cursor: pointer;
}
#home-slide-prev-div,
#home-slide-next-div {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  z-index: 1000;
}
#home-slide-prev-div {
  left: 0;
}
#home-slide-next-div {
  right: 0;
}
#home-carousel .carousel-inner .carousel-item .carousel-caption h3 span {
  color: #f3b455;
}
#home-carousel .carousel-inner .carousel-item .carousel-caption h3 {
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
}
.purpose-txt {
  white-space: break-spaces;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1700px) {
  .cert-1 {
    max-width: 400px !important;
    width: 250px;
    margin-right: 50px !important;
  }
}
@media (min-width: 1400px) {
  #home-carousel .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 40px !important;
  }
  #home-carousel .carousel-inner .carousel-item .carousel-caption {
    font-size: 20px !important;
  }
  .header-content h2 {
    font-size: 35px !important;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  #home-carousel .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 30px !important;
  }
  #home-carousel .carousel-inner .carousel-item .carousel-caption {
    font-size: 18px !important;
  }
}
@media (max-width: 991px) {
  #home-carousel .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 26px;
  }
  #home-carousel .carousel-inner .carousel-item .carousel-caption {
    font-size: 16px !important;
  }
  .header-content {
    padding: 26px !important;
  }
  .header-content h2 {
    font-size: 25px !important;
  }
  .header-content .line {
    border: 6px solid #111c55 !important;
  }
  .cert-1 {
    max-width: 200px !important;
    height: fit-content;
    padding: 10px 10px 0 10px !important;
    /* border: 2px  solid; */
  }
  .home-purpose {
    bottom: 10px!important;
    left: 50%!important;
    transform: translate(-50%);
  }
  .text-quote {
    font-size: 14px !important;
  }
  .purpose-txt {
    font-size: 12px !important;
  }
  .home-certificate {
    bottom: 10px !important;
  }
  .font-weight-bold {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .company-txt {
    display: none;
  }
  .cert-number p {
    padding: 2px;
    font-size: 12px !important;
  }
  .home-certificate {
    display: none !important;
  }
}
@media (max-width: 768px) {
  #home-carousel .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 20px !important;
  }
  .header-content h2 {
    font-size: 18px !important;
  }
}
@media (max-width: 576px) {
  #home-slide-prev-div,
  #home-slide-next-div {
    display: none;
  }
  #home-carousel .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 20px;
  }
  .header-content {
    padding: 10px !important;
  }
  .header-content h2 {
    font-size: 15px !important;
  }
  .header-content .line {
    border: 4px solid #111c55 !important;
  }
  .home-purpose {
    width: 90%!important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .home-purose-title {
    font-size: 18px !important;
  }
  .home-purpose {
    padding: 10px 15px !important;
    right: 20px !important;
  }
  .text-quote {
    font-size: 20px !important;
  }
  .cert-1 {
    max-width: 200px !important;
    height: fit-content;
    padding: 10px 10px 0 10px !important;
    /* border: 2px  solid; */
  }
  .font-weight-bold {
    font-size: 16px !important;
  }
  .cert-number p {
    padding: 2px;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .home-purose-title {
    font-size: 16px !important;
  }
  .home-purpose {
    padding: 10px 15px !important;
    right: 2% !important;
    width: 250px !important;
    bottom: 20px !important;
  }
  .text-quote {
    font-size: 18px !important;
  }
  .cert-1 {
    max-width: 200px !important;
    height: fit-content;
    padding: 10px 10px 0 10px !important;
    /* border: 2px  solid; */
  }
  .home-certificate {
    bottom: 10px !important;
  }
  .font-weight-bold {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .company-txt {
    display: none;
  }
  .cert-number p {
    padding: 2px;
    font-size: 12px !important;
  }
}
@media screen and (max-height:910px) {
  .img-slide {
    height: 90vh;
  }
  
}
@media screen and (max-height: 768px) {
  .header-content .line {
    display: none !important;
  }
}
.header-content {
  position: relative;
  padding: 56px;
  overflow: hidden;
  text-align: left;
}
.header-content .line {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  border: 9px solid #111c55;
  -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
  clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
}
.header-content h2 {
  font-weight: 300;
  font-size: 35px;
  color: #fff;
}
.header-content h1 {
  font-size: 56px;
  font-weight: 600;
  margin: 5px 0 20px;
  word-spacing: 3px;
  color: #fff;
}
.header-content h4 {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #fff;
}

.owl-item.active h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 0.3s;
}
.owl-item.active h2 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 0.3s;
}
.owl-item.active h4 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInUp";
  animation-delay: 0.3s;
}
.owl-item.active .line {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInLeft";
  animation-delay: 0.3s;
}
.img-caption-hide {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background: rgba(3, 6, 41, 0.31);
}
.home-purpose {
  position: absolute;
  right: 20px;
  bottom: 50px;
  width: 400px;
  background: #27201991;
  color: #fcfcfd;
  padding: 20px 20px 30px 20px;
  border-radius: 30px;
  z-index: 1001;
  text-shadow: none;
  /* border: 2px solid; */
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomIn";
  animation-delay: 0.3s;
}
.home-purose-title {
  font-size: 22px;
  font-weight: bold;
}
.home-certificate {
  position: absolute;
  left: 20px;
  bottom: 50px;
  z-index: 1001;
  text-shadow: none;
  display: flex;
  flex-direction: row;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomIn";
  animation-delay: 0.3s;
}
.slogan-slide-2 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomIn";
  animation-delay: 0.3s;
}
.cert-1 {
  max-width: 230px;
  background: #111c55bd;
  color: #fcfcfd;
  border-radius: 20px;
  padding: 10px;
  margin-right: 30px;
  /* border: 2px  solid; */
}
.cert-number {
  display: inline-flex;
}
.cert-number p {
  border: 1px solid;
  padding: 5px;
}
.text-quote {
  font-size: 30px;
  margin-bottom: 0;
}
</style>