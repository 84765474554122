<template>
  <section id="home-services" class="home-services">
    <b-container fluid class="pl-0 pr-0 service-container">
      <!-- <b-row class="home-services-title wow fadeInUp" data-wow-delay="0.3s">
        <b-col md="12">
          <div>
            <h1 class="home-item-title">{{ $t("service_title_0") }}</h1>
            <div class="bord-bot"></div>
          </div>
        </b-col>
      </b-row> -->
      <b-row lass=" wow fadeInUp" data-wow-delay="0.3s">
        <b-col sm="12" class="service-bg">
          <div
            class="mx-auto related-text"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h1 class="vision-txt mt-5">ASSOCIATED COMPANIES</h1>
            <h2 class="mt-5 font-weight-bold-vision">
              「協力と共に、成功へ」
            </h2>
            <div class="text-introduce pl-2 pr-2">
              <p class="text-center text-white-space mt-5">
                {{ $t("associated_introduce") }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col lg="10" class="mx-auto related-company">
          <b-row>
            <b-col
              md="5"
              class="home-service-items px-4"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-bottom"
            >
              <div class="home-service-item p-2">
                <img
                  src="../assets/asukka-antt-logo.png"
                  width="80%"
                  alt="株式会社ASUKA,人材紹介"
                />
                <h5 class="company-sub">{{ $t("service_title_1") }}</h5>
                <hr class="home-services-item-line" />
                <p>
                  {{ $t("service_introduce_1") }}
                </p>
                <button
                  class="advance-show"
                  @click="handleMovePage('company-ant')"
                >
                  もっと見る
                </button>
              </div>
            </b-col>
            <b-col md="2" sm=""></b-col>
            <b-col
              md="5"
              class="home-service-items px-4"
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1500"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-bottom"
            >
              <div class="home-service-item p-2">
                <img
                  src="../assets/asuka-cvtech-logo2.png"
                  width="80%"
                  alt="株式会社ASUKA,人材紹介"
                />
                <h5 class="company-sub">&nbsp;</h5>
                <hr class="home-services-item-line" />
                <p>
                  {{ $t("service_introduce_2") }}
                </p>
                <button
                  class="advance-show"
                  @click="handleMovePage('company-cvtech')"
                >
                  もっと見る
                </button>
              </div>
            </b-col>
            <b-col
              md="6"
              sm=""
              class="home-service-items px-4 mx-auto"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-bottom"
            >
              <div class="home-service-item p-2">
                <img src="../assets/asuka-mha-logo.png" width="80%" alt="株式会社ASUKA、人材紹介" />
                <h5 class="company-sub">{{ $t("service_title_3") }}</h5>
                <hr class="home-services-item-line" />
                <p>
                  {{ $t("service_introduce_3") }}
                </p>
                <button
                  class="advance-show"
                  @click="handleMovePage('company-mha')"
                >
                  もっと見る
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "home-service",
  methods: {
    handleMovePage(pagePath) {
      this.$router.push(pagePath);
    },
  }
};
</script>

<style>
#home-services {
  /* background: #fcfcfd; */
  /* background-image: url("../assets/background_wave.png"); */
  /* background-image: url("../assets/asuka_bg.png"); */
  /* background-size: cover; */
  /* background: #e9ecef; */
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  padding-top: 0;
  font-size: 1.1rem;
}
@media (min-width: 2400px) {
  .related-company {
    max-width: 1480px;
  }
}

.service-bg {
  width: 100%;
  height: fit-content;
  background-image: url("../assets/background_wave.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 70px;
}
.related-text {
  padding-top: 15%;
}
.text-white-space {
  white-space: pre;
}
.related-company {
  margin-top: 5%;
}
.company-sub {
  font-weight: bold;
}
@media (min-width: 768px) {
  .text-introduce {
    width: 80%;
    margin: auto;
  }
}

.home-services-title {
  /* background-color: #111c55; */
  color: #111c55;
  margin-bottom: 20px;
}
.home-service-items {
  cursor: pointer;
  transition: all 0.3s ease;
}
/* .home-service-items:hover {
  animation: example 0.3s;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, #111c55 0px 10px 10px;
}
@keyframes example {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.0);
  }
} */

.home-services-item-line {
  background: #111c55;
  height: 2px;
}
</style>