<template>
  <div id="home-nav" class="web-nav-bar">
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-navbar toggleable="lg" type="dark" id="home-b-nav">
            <b-navbar-brand id="home-nav-brand" href="#">
              <b-img
                left
                src="../assets/logo.jpg"
                height="40"
                alt="asuka,人材日本、Việt Nam,Nhật Bản"
                @click="handleMoveToHome"
              ></b-img>
            </b-navbar-brand>

            <b-navbar-toggle id="home-nava-small" target="nav-collapse"
              ><font-awesome-icon icon="fa-solid fa-bars"
            /></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto navi-bar-fix">
                <b-nav-item
                  @click="setTab(1)"
                  to="/"
                  :active="tab == 1"
                  class="home-nav-item"
                  >{{ $t("nav-home") }}</b-nav-item
                >
                <b-nav-item
                  :active="tab == 2"
                  class="home-nav-item"
                  @click="setTab(2)"
                  to="/overview"
                  >{{ $t("nav-corporate") }}</b-nav-item
                >
                <!-- <b-nav-item
                  :active="tab == 3"
                  class="home-nav-item"
                  @click="handleScrollIntoView('home-services')"
                  >{{ $t("nav-service") }}</b-nav-item
                > -->
                <b-nav-item
                  :active="tab == 4"
                  class="home-nav-item"
                  click="setTab(4)"
                  to="/contact"
                  >{{ $t("nav-contact") }}</b-nav-item
                >
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "navi-bar",
  mounted() {
    $(document).ready(function fixedHeader() {
      var windows = $(window);
      var screenSize = windows.width();
      var sticky = $("#home-nav");
      windows.on("scroll", function () {
        var scroll = windows.scrollTop();
        var headerHeight = sticky.height();
        let jumpBtn = $("#scroll-top");
        let antBackBtn = $("#ant-back-area");
        let cvtechBtn = $("#cvtech-back-area");
        let mhaBtn = $("#mha-back-area");
        if (screenSize >= 320) {
          if (scroll < headerHeight) {
            jumpBtn.css({ display: "none" });
            antBackBtn.css({ display: "none" });
            cvtechBtn.css({ display: "none" });
            mhaBtn.css({ display: "none" });
            sticky.removeClass("is_sticky");
          } else {
            jumpBtn
              .css({ opacity: 0, display: "block" })
              .animate({ opacity: 1 }, 500);
            cvtechBtn.css({ display: "block" });
            mhaBtn.css({ display: "block" });
            antBackBtn.css({ display: "block" });
            sticky.addClass("is_sticky");
          }
        }
      });
    });
  },
  computed: {
    ...mapGetters(["tab"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setTab"]),
    handleClickJP() {
      this.$i18n.locale = "ja";
    },
    handleClickVN() {
      this.$i18n.locale = "vn";
    },
    handleScrollIntoView(id) {
      if (this.$route.name == "Home") {
        const elemetn = document.getElementById(id);
        if (elemetn) {
          switch (id) {
            case "home-services":
              this.setTab(3);
              break;
            case "home-footer":
              this.setTab(4);
              break;
            case "conent":
              this.setTab(1);
              break;
          }
          elemetn.scrollIntoView({ behavior: "smooth", delay: 0 });
        }
      } else {
        this.$router.push({ name: "Home", hash: "#" + id });
      }
    },
    handleMoveToHome() {
      console.log(this.$route.name);
      if (this.$route.name == "Home") {
        window.location.reload();
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style>
#home-nav {
  /* background-color: #111c55; */
  background-color: #fff;
  /* border-bottom: 3px solid #d5eef5; */
  /* border-top: 1px solid #111c55; */
  /* border-bottom: 5px solid #111c55; */
}
#home-nav-brand {
  cursor: pointer;
}
.home-nav-item .nav-link {
  /* color: #d9d9d7 !important; */
  color: #111c55 !important;
  padding: 1.1em 1.6em !important;
  font-size: 16px;
  font-weight: 600;
}
.navi-bar-fix {
  min-width: 35%;
  justify-content: space-around;
}
.home-nav-item .nav-link:hover {
  /* color: rgb(231, 247, 14) !important; */
  color: rgb(223, 167, 14) !important;
}
.home-nav-item .nav-link.active {
  /* color: rgb(231, 247, 14) !important; */
  /* color: rgb(223, 167, 14) !important; */
  text-decoration: underline #f6b024 3px;
}
#home-nav-lang .dropdown-menu {
  min-width: 1rem !important;
  top: 2.2rem !important;
}
#home-nav-lang-exp .dropdown-menu {
  min-width: 1rem !important;
  top: 2.2rem !important;
  width: 100px;
  margin: auto;
}

#home-nav-lang-exp .nav-link {
  color: #111c55;
  font-weight: 600;
}
#home-nav-lang .dropdown-menu .dropdown-item {
  display: inline-flex !important;
  align-items: center !important;
}
#home-nav-lang .dropdown-menu .dropdown-item:hover,
#home-nav-lang-exp .dropdown-menu .dropdown-item:hover {
  background-color: #003977;
  color: white;
}
#home-nava-small {
  /* color: white; */
  color: #111c55 !important;
}
:deep(.nav-link-active) {
  text-decoration: underline #f6b024 2px;
}
@media (max-width: 991.98px) {
  #home-nav-lang {
    display: none;
  }
  #home-nav-lang-exp {
    display: block;
  }
}
@media (min-width: 991.98px) {
  #home-nav-lang {
    display: block;
  }
  #home-nav-lang-exp {
    display: none;
  }
}
</style>