<template>
  <footer id="home-footer">
    <b-container fluid>
      <b-row>
        <b-col class="col-md-6" cols="12">
          <div class="home-footer-company-info d-flex flex-column">
            <img
              left
              id="footer-logo"
              src="../assets/logo.png"
              width="229"
              alt="株式会社ASUKA,人材紹介"
            />
            <p class="pl-3">
              〒455-0073 愛知県名古屋市港区川西通4-9-2 ASUKAビル3F
            </p>
            <p class="pl-3">Tel: 052-977-9445</p>
            <p class="pl-3">Fax: 052-977-4660</p>
            <p class="copyright-1 pl-3 pt-3">
              Copyright &copy; 2023 by Asuka global
            </p>
          </div>
        </b-col>
        <b-col class="col-md-6" cols="12">
          <div class="home-footer-map">
            <ul class="footer-nav">
              <li @click="handleMoveToHome">ホーム</li>
              <li @click="handleMoveToCoor">企業情報</li>
              <li @click="handleMoveToContact">問い合わせ</li>
            </ul>
          </div>
          <div class="home-footer-sns">
            <font-awesome-icon
              class="home-footer-sns-icon"
              icon="fa-brands fa-facebook"
              alt="株式会社ASUKA、人材紹介"
              size="2xl"
              @click="moveToFacebook"
            />
            <font-awesome-icon
              class="home-footer-sns-icon"
              icon="fa-solid fa-envelope"
              size="2xl"
              alt="株式会社ASUKA、人材紹介"
              @click="handleOpenMail"
            />
            <img
              class="home-footer-sns-icon"
              src="@/assets/asuka_footer_sdg.png"
              width="40%"
              alt="株式会社ASUKA、人材紹介"
            />
          </div>
          <p class="copyright-2">Copyright &copy; 2023 by Asuka global</p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "home-footer",
  methods: {
    moveToFacebook() {
      window.open("https://www.facebook.com/asuka6666", "_blank");
    },
    handleOpenMail() {
      window.open("mailto:truong@asuka-n.jp");
    },
    handleMoveToHome() {
      if (this.$route.name == "Home") {
        window.location.reload();
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    handleMoveToCoor() {
      if (this.$route.name == "overview") {
        window.location.reload();
      } else {
        this.$router.push({ name: "overview" });
      }
    },
    handleMoveToContact() {
      if (this.$route.name == "contact") {
        window.location.reload();
      } else {
        this.$router.push({ name: "contact" });
      }
    },
  },
};
</script>

<style>
#home-footer {
  background: #eef1f6;
  color: #111c55;
  /* background-image: url("../assets/system-bg.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 30px;
}
#home-footer .container .row {
  padding: 16px 0 5px;
}
.home-footer-company-info h3 {
  font-size: 65px;
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
  color: #f4fc53;
}
.home-footer-company-info {
  text-align: left;
  width: 60%;
  margin: auto;
}
.home-footer-sns-icon {
  cursor: pointer;
  padding-right: 10px;
  margin: 0 10px 10px 10px;
}
#footer-logo {
  float: left;
}
#footer-introduce {
  float: left;
}
#home-footer-company-addr {
  text-align: left;
}
#footer-mail {
  display: inline-flex;
}

#logo-asuka {
  margin: auto;
}
.home-footer-sns {
  width: 60%;
  text-align: right;
  margin: auto;
  margin-top: 50px;
}

/* ex3 */
.footer-nav {
  list-style: none;
  text-align: left;
  display: inline-flex;
  width: 60%;
  justify-content: space-around;
}
.footer-nav li {
  padding: 4px 0;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
}
.copyright-2 {
  display: none;
}

.footer-map {
  cursor: pointer;
}
@media screen and (max-width: 1400px) {
  .footer-nav {
    width: 95%;
  }
  .home-footer-sns {
    width: 95%;
  }
  .home-footer-sns-icon {
    max-width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .copyright-1 {
    display: none;
  }
  .footer-nav {
    display: none;
  }
  .home-footer-sns {
    margin-top: 10px;
    width: 100%;
    text-align: unset;
  }
  .copyright-2 {
    display: block;
  }
  .footer-nav li {
    font-size: 1rem;
    font-weight: 500;
  }
}
</style>