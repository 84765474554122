<template>
  <section id="about" class="about">
    <b-container class=" about-bg" fluid>
      <b-row class="w-100">
        <b-col
          lg="4"
          class="about-card"
          data-aos="fade-right"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="about-cont" @click="handleMovePage('foreign-info')">
            <b-img src="../assets/asuka_gaikokujin.jpg" class="about-img" alt="株式会社ASUKA、人材紹介" fluid />
            <h3 class="home-about-title">
              外国人滞在情報
            </h3>
            <p class="p-2">
               {{$t("about_introcude_0")}}
            </p>
          </div>
          <!-- <router-link class="advance-show" to="/foreign-info">詳細を見る <font-awesome-icon icon="fa-solid fa-long-arrow-right" size="xl" /></router-link> -->
          <button class="advance-show mt-5" @click="handleMovePage('foreign-info')">もっと見る</button>
        </b-col>
        <b-col
          lg="4"
          class=" about-card"
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="about-cont second" @click="handleMovePage('visa-engineer')">
            <b-img class="about-img" src="../assets/asuka_gijutsu.jpg" alt="株式会社ASUKA、人材紹介" fluid />
            <h3 class="home-about-title">
              技術ビザ
            </h3>
            <p class="p-2">
              {{$t("about_introcude_1")}}
            </p>
          </div>
           <button class="advance-show mt-5" @click="handleMovePage('visa-engineer')">もっと見る</button>
        </b-col>
        <b-col
          lg="4"
          class="about-card"
          data-aos="fade-left"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="about-cont" @click="handleMovePage('visa-tokuteigino')">
            <b-img class="about-img" src="../assets/asuka_ginojishusei.jpg" alt="株式会社ASUKA、人材紹介" fluid />
            <h3 class="home-about-title">
              特定技能ビザ
            </h3>
            <p class="p-2">
              {{$t("about_introcude_2")}}
            </p>
          </div>
           <button class="advance-show mt-5" @click="handleMovePage('visa-tokuteigino')">もっと見る</button>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "home-about",
  methods: {
    handleMovePage(pagePath) {
      this.$router.push(pagePath);
    }
  }
};
</script>

<style>
#about {
  /* background: #fcfcfd; */
  padding-top: 0px;
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  font-size: 1.1rem;
}
.about-bg .row{
  width: 80%;
  margin: auto;
  max-width: 1480px;
}
@media (min-width: 2700px) {
  .about-bg .row{
  max-width: 1480px;
}
}
.about-bg {
        /* background-image: url("../assets/asuka_bg.png");
  background-size: cover; */
  padding-top: 10%;
}
#about .about-cont {
    padding: 3px 0 10px 0;
    /* box-shadow: 1px 1px 0 rgb(0 0 0 / 10%); */
    background: #f8f9fa;
    /* border-radius: 16px 16px 5px 5px;
    border-bottom: 5px solid #111c55; */
    height: 600px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@media  (max-width:1750px) {
  #about .about-cont {
    height:600px!important;
  }
}
@media  (max-width:992px) {
  #about .about-cont {
    height:700px!important;
  }
}
@media  (max-width:767px) {
  #about .about-cont {
    height:fit-content!important;
  }
  .about-card {
    width: 80%;
    left: 50%!important;
    transform: translateX(-50%)!important;
    position: relative;
  }
}
@media screen and (max-width: 576px) {
  .about-card {
    width: 100%;
    left: 50%!important;
    transform: translateX(-50%)!important;
    position: relative;
    padding-left:0!important ;
     padding-right:0!important ;
   
  }
  #about .about-cont {
     border: none!important;
     box-shadow: unset;
     background: unset;
     padding-left: 0!important;
     padding-right: 0!important;
  }
  .about-cont p {
    padding: unset!important;
  }
}
.about-card {
  cursor: pointer;
}
.about-img {
    transition: 0.3s all ease-in-out;
    /* border-radius: 16px 16px 0 0; */
}

.about-img:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transform: translateY(-0.35em);
}
.home-about-title {
  font-weight: bold;
  padding: 30px 0;
  /* text-decoration: #f6b024 underline 5px; */
}
.advance-show {
  background: #111c55;
  height: 50px;
  width: 200px;
  color: #fff;
  border: none;
  box-shadow: none;
  font-size: 1.07rem;
  margin-bottom: 50px;
  cursor: pointer;
  border-radius: 4px;
}
.advance-show:hover {
  /* background: #333; */
  border-radius: 19px;
  scale: 1.1;
  background: rgb(223, 167, 14) !important;
  color: #111c55;
  font-weight: bolder;
  transition: all 0.3s ease 0s;
}
@media (max-width: 768px) {

  .about-bg .row{
    width: 100%!important;
  }
}


</style>