<template>
<div>
 <b-container class="contact-container">
    <b-row class="w-100 ml-0 mr-0">
      <b-col class="w-100">
        <h1>株式会社ASUKA</h1>
        <table class="contact-table">
          <tr>
            <td class="contact-tbl-left">TEL</td>
            <td>052-977-9445</td>
          </tr>
          <tr>
            <td class="contact-tbl-left">FAX</td>
            <td>052-977-4660</td>
          </tr>
          <tr>
            <td class="contact-tbl-left">MAIL</td>
            <td>truong@asuka-n.jp</td>
          </tr>
          <tr>
            <td class="contact-tbl-left">ADDRESS</td>
            <td>〒455-0073愛知県名古屋市港区川西通4-9-2ASUKAビル3F</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="w-100 ml-0 mr-0">
      <b-col class="w-100">
        <div class="contact-box">
          <h2>CONTACT</h2>
          <div @click="handleOpenMail">
            <font-awesome-icon
              class="home-footer-sns-icon"
              icon="fa-solid fa-envelope"
              size="2xl"
              alt="株式会社ASUKA、人材紹介"
            />
            <span>お問い合わせはこちら</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
    <div class="w-100 contact-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52202.51248507835!2d136.8521914347273!3d35.14023141705789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60037940ce2ff063%3A0xd42049b2ba49bff7!2z5qCq5byP5Lya56S-QVNVS0E!5e0!3m2!1svi!2sjp!4v1698154232068!5m2!1svi!2sjp" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
 
</template>

<script>
import { mapActions } from 'vuex';
export default {
    methods:{
        ...mapActions(["setTab"]),
        handleOpenMail() {
           window.open('mailto:truong@asuka-n.jp');
        }
    },
    mounted() {
        this.setTab(4)
    }
};
</script>

<style scoped>
.contact-container {
  font-size: 1.1rem;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomInUp";
  animation-delay: 0.4s;
}
.contact-container h1 {
  font-weight: bolder;
  margin-top: 10%;
}
.contact-table {
  width: 70%;
  position: relative;
  margin: auto;
  margin-top: 50px;
}
.contact-tbl-left {
  background-color: #f6f6f6f6;
  width: 30%;
}
.contact-table tr {
  border-bottom: 1px solid #e3e3e3;
}
.contact-table tr td {
  padding: 20px 0;
}
.contact-box {
  width: 70%;
  padding: 30px;
  margin: auto;
  border: 1px solid;
  margin-top: 10%;
}
.contact-box h2 {
    font-weight: bolder;
}
.contact-box  div {
    display: inline-flex;
    align-content: center;
    align-items: center;
    /* width: 80%; */
    margin: auto;
    position: relative;
    background: #333;
    padding: 15px 20%;
    color: #fff;
    margin-top: 50px;
    cursor: pointer;
}
.contact-box  div span {
    font-size: 1.2rem;
    font-weight: bolder;
}
.contact-map {
    margin-top: 7%;
}
.contact-map iframe {
    width: 100%;
    height: 400px;
    filter: grayscale(1);
}
@media screen and (max-width: 768px) {
    .contact-table,
    .contact-box {
        width: 100%;
    }
    .contact-box  div span {
        font-size: 1.1rem;
    }
    .contact-container h1 {
        font-size: 2rem;
    }
}
</style>