<template>
  <b-container class="antt-container pl-0 pr-0" fluid>
    <b-row class="antt-header-row w-100 pl-0 pr-0 ml-0 mr-0">
      <b-col md="12 pl-0 pr-0 pb-0">
        <figure class="antt-figure">
          <img
            src="@/assets/antt-header.png"
            alt="株式会社ASUKA"
            style="width: 100%"
          />
        </figure>
        <div class="img-hide"></div>
        <div class="antt-header-intro">
          <div class="antt-intro-content">
            <img
              class="antt-img"
              src="@/assets/antt-header-logo.png"
              width="40%"
              alt="株式会社ASUKA、人材紹介"
            />
            <p class="antt-intro-h3">Accompany & Trust</p>
            <p class="antt-intro-txt">
              ANT
              協同組合は、2022年9月に設立され、信頼とサポートを提供します。<br />
              21世紀はグローバルで不確実性の高い時代で、ANTは企業の競争力と人材不足の課題に焦点を当て、<br />
              国際的な協力を推進し、ビジネスの成長を支援します。
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="ant-back-area" @click="handleBack">
          <font-awesome-icon class="back-btn" icon="fa-solid fa-circle-left" />
        </div>
      </b-col>
    </b-row>
    <b-row
      class="w-100 ml-0 mr-0 mt-5 antt-intro-txt1"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <b-col class="w-100 text-center">
        <p class="text-center">
          ANT 協同組合は、2022年9月に設立され、信頼とサポートを提供します。<br />
          21世紀はグローバルで不確実性の高い時代で、ANTは企業の競争力と人材不足の課題に焦点を当て、<br />
          国際的な協力を推進し、ビジネスの成長を支援します。
        </p>
      </b-col>
    </b-row>
    <b-row
      class="antt-overview-row w-100 ml-0 mr-0"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <b-col class="w-100">
        <div class="antt-overview-content w-100">
          <h1>ANT協同組合</h1>
          <p>
            21世紀は、人、モノ、試算がグローバルに動く時代。<br />
            さらに、ポストコロナ、世界は先の予測ができないVUCA時代に取り巻かれます。<br />
            <strong>Volatility</strong>
            (変動性、ものごとの急激な移り変わりやすさ)<br />
            <strong>Uncertainty</strong> (不確実性、確信が持ってない状態)<br />
            <strong>Complexity</strong>
            (複雑性、ものごとが複雑すぎて本質の理解が困難)<br />
            <strong>Ambiguity</strong>
            (曖昧性、1つの事象に対して考えられる解釈が複数)<br />
            という4単語の頭文字からつくられた言葉であり、この時代において<br />
            企業にとっては経営競争力・人材競争力がキーとなっております。<br />
            我々は、2022年9月から「ANT協同組合」として、新たにスタート。<br />
            組合名の「ANT」には「<strong>お客様のビジネスに寄り添い、信頼関係を築きいく</strong>」<br />
            という意味が込められています。ベトナム企業との関係をはじめ、<br />
            国際的な結びつきを含め、日本企業の人材不足課題解決、加盟各社の<br />
            事業展開のお役に立てるよう、邁進してまいります。
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row class="antt-activities-row w-100 ml-0 mr-0">
      <b-col
        md="6"
        class="antt-activities-left w-100"
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <figure>
          <img src="@/assets/antt-act-img2.png" alt="株式会社ASUKA、人材紹介" />
          <figcaption>日本語授業</figcaption>
        </figure>
        <figure>
          <img src="@/assets/antt-act-img3.png" alt="株式会社ASUKA、人材紹介" />
          <figcaption>技能実習生作業訓練</figcaption>
        </figure>
      </b-col>
      <b-col
        md="6"
        class="antt-activities-right"
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <figure>
          <img src="@/assets/antt-act-img1.png" alt="株式会社ASUKA、人材紹介" />
          <figcaption>体育授業</figcaption>
        </figure>
      </b-col>
    </b-row>
    <b-row
      class="employee-institution-row w-100 ml-0 mr-0"
      data-aos="fade-up"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <b-col class="w-100">
        <div class="w-100 employee-institution-content">
          <h1>外国人実習生制度</h1>
          <p class="p-fisrt">
            開発途上国の優秀若者を3年受け入れて、働きながら日本の優れた技術や仕事など学んでもらう制度です。
          </p>
          <div>
            <p>
              プラス2年間と特定技能へ変更可能<br />
              ・人材を数倍の応募者から「能力の高い」人を選抜できます。<br />
              ・3年間勤務、「安定した労働力の確保」ができます。<br />
              ・「若者」と「高いモチベーション」がある人材は企業を生活化されます。<br />
              ・「労務コスト削減」に繋がります。<br />
              ・企業の「会社的、国際的貢献度」が高まります。<br />
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      class="employee-institution-plus-row w-100 ml-0 mr-0"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <b-col class="w-100">
        <div class="w-100 employee-institution-plus-content">
          <h1>実習生制度のプラス面・マイナス面</h1>
          <h3 class="text-left">プラス面(+)</h3>
          <p class="p-fisrt">
            <strong>優秀選抜</strong><br />
            人材を数倍の応募者から「能力の高い」「貴社の社風に合った」<br />
            人物を選抜できます。年齢、国、地域、能力、性格も<br />
            貴社の希望に沿った人材を募集してきます。<br />
          </p>
          <p>
            <strong>安定性（3年）</strong><br />
            「3年間継続して」貴社で実習します。勤勉なため、欠勤を重ねることは<br />
            ほとんどありません。<br />
          </p>
          <div class="plus-area">
            <h1 class="plus-icon">(+)</h1>
            <p>
              <strong>若さ・意欲</strong><br />
              技能を学ぶ意欲が高く、習得の早い年齢層の若者です。「若さ」と<br />
              「高いモチベーション」がある人材が貴社を活性化します。<br />
            </p>
          </div>

          <p>
            <strong>即戦力</strong><br />
            同業職種に在籍し、貴社に勤務する教育を受けています<br />
            ので、短期間で即戦力になる人材です。<br />
          </p>
          <p>
            <strong>人材育成</strong><br />
            若手社員に実習生を教えさせることでの指導力習得と国際感覚が<br />
            身に付きます。海外進出や海外取引の可能性が出します。<br />
          </p>
          <p><strong>学務コスト</strong><br /></p>
        </div>
      </b-col>
    </b-row>
    <b-row
      class="employee-institution-minus-row w-100 ml-0 mr-0"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <b-col class="w-100">
        <div class="w-100 employee-institution-minus-content">
          <h3 class="text-left">マイナス(ー)</h3>
          <p class="p-fisrt">
            <strong>日本語力</strong><br />
            6ヶ月間日本語を学んで、日本検定4級レベルを合格することです。<br />
            （日常会話をゆっくり話す程度）<br />
          </p>

          <div class="plus-area">
            <h1 class="plus-icon">(ー)</h1>
            <p>
              <strong>寮管理</strong><br />
              ゴミの分別・共同生活や掃除の指導が必要です。寮を準備する必要があります。<br />
              (家賃は実習生の本人分担)<br />
            </p>
          </div>
          <p>
            <strong>3年契約</strong><br />
            最初に3年間の雇用責任が企業にあります<br />
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.antt-container {
  font-size: 1.1rem;
  color: #000;
}
.antt-header-intro {
  position: absolute;
  height: 100%;
  width: 60%;
  color: red;
  left: 0;
  top: 0;
  z-index: 2;
}
.img-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(3, 6, 41, 0.31);
  /* background: red; */
}
.antt-intro-h3 {
  color: #fff;
  font-size: 3rem;
  font-weight: bolder;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 0.6s;
}
.antt-intro-txt {
  color: #fff;
  font-size: 0.9em;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 0.9s;
}
.antt-img {
  background-color: #c2b8b8e0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomIn";
  animation-delay: 0.2s;
}
.antt-intro-content {
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-20%);
  height: 100%;
  margin-left: 15px;
}
.antt-figure {
  margin: unset !important;
}
.antt-intro-txt1 {
  display: none;
}
.antt-overview-content {
  margin-top: 10%;
}
.antt-overview-content h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.antt-overview-content p {
  margin-top: 50px;
  line-height: 2;
  letter-spacing: 0.1em;
}
.antt-activities-row {
  margin-top: 10%;
  background-image: url("../assets/asuka_bg.png");
  background-size: cover;
  padding: 120px 0 150px;
}
.antt-activities-left {
  align-items: flex-end;
  padding-right: 75px;
  display: flex;
  flex-direction: column;
}
.antt-activities-left figure {
  /* display: flex;
  flex-direction: column; */
  width: 55%;
  max-width: 500px;
  position: relative;
  float: right;
}
.antt-activities-left figcaption {
  text-align: center;
  margin-top: 15px;
}
.antt-activities-left img {
  width: 100%;
}
.antt-activities-left figure:last-child {
  margin-top: 25%;
}
.antt-activities-right {
  text-align: left;
  padding-left: 75px;
}
.antt-activities-right figure {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 500px;
}
.antt-activities-right figcaption {
  text-align: center;
  margin-top: 15px;
}
.antt-activities-right img {
  width: 100%;
}
.employee-institution-content {
  /* width: 80% !important;
  left: 10%; */
  width: fit-content !important;
  margin: 10% auto 0;
  text-align: center;
  position: relative;
}
.employee-institution-content h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.p-fisrt {
  margin-top: 50px;
  line-height: 2;
  letter-spacing: 0.1em;
}
.employee-institution-content div {
  position: relative;
  left: 10%;
}
.employee-institution-content div p {
  text-align: left;
  line-height: 2;
  letter-spacing: 0.1em;
}
.employee-institution-plus-content {
  margin-top: 10%;
  text-align: center;
  position: relative;
  background-image: url("../assets/background_wave.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 70px;
}
.employee-institution-plus-content h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.employee-institution-plus-content h3 {
  margin-top: 50px;
  font-weight: bolder;
  display: none;
}
.employee-institution-plus-content p {
  line-height: 2;
  letter-spacing: 0.1em;
}
.employee-institution-plus-content p:first {
  margin-top: 50px;
}
.plus-area h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}

.plus-area {
  display: inline-flex;
  align-items: center;
}
.plus-icon {
  position: absolute;
  left: 15%;
}
.employee-institution-minus-content {
  margin-top: 5%;
  margin-bottom: 150px;
}
.employee-institution-minus-content h3 {
  margin-top: 50px;
  font-weight: bolder;
  display: none;
}
#ant-back-area {
  display: none;
}
#ant-back-area {
  font-size: 40px;
  text-align: left;
  position: fixed;
  left: 10px;
  top: 80px;
  cursor: pointer;
  z-index: 1000;
}
@media screen and (max-width: 1300px) {
  .antt-intro-content {
    top: 50%;
    transform: translateY(-30%);
  }
  .antt-activities-right figure,
  .antt-activities-left figure {
    width: 75%;
  }
  .plus-icon {
    left: 7%;
  }
}
@media screen and (max-width: 992px) {
  .antt-intro-content {
    top: 50%;
    transform: translateY(-40%);
  }
  .antt-intro-h3 {
    font-size: 2rem;
  }
  .antt-activities-right figure,
  .antt-activities-left figure {
    width: 85%;
  }
  .antt-activities-left {
    padding-right: 35px;
  }
  .antt-activities-right {
    padding-left: 35px;
  }
  .employee-institution-plus-content h3 {
    display: block;
  }
  .employee-institution-minus-content h3 {
    display: block;
  }
  .plus-icon {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .antt-container {
    font-size: 1rem;
  }
  .antt-header-intro {
    width: 100%;
  }
  .antt-img {
    width: 30%;
  }
  .antt-intro-txt {
    width: 90%;
    left: 5%;
    position: relative;
  }
  .antt-activities-left {
    align-items: center;
  }
  .antt-activities-right figure,
  .antt-activities-left figure {
    width: 100%;
    float: unset;
    max-width: unset;
  }
  .antt-activities-left {
    padding: 0 30px;
  }
  .antt-activities-right {
    padding: 0 30px;
  }
  .antt-overview-content p {
    text-align: left;
  }
  .employee-institution-content p {
    text-align: left;
  }
  .employee-institution-content div {
    left: unset;
  }
}
@media screen and (max-width: 576px) {
  .antt-intro-txt {
    display: none;
  }
  .antt-intro-txt1 {
    display: block;
  }
  .antt-intro-content {
    top: 50%;
    transform: translateY(-25%);
  }
  .antt-container h1 {
    font-size: 1.5rem !important;
  }
  .antt-container h3 {
    font-size: 1.3rem !important;
  }
}
</style>