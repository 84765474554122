<template>
  <b-container class="tokutei-info" fluid>
    <b-row class="flex-column">
      <b-col class="pl-5">
        <div class="back-area" @click="handleBack">
          <font-awesome-icon class="back-btn" icon="fa-solid fa-circle-left" />
        </div>
      </b-col>
      <b-col class="pl-5 mt-5">
        <h1 id="tokutei-title" class="ml-3">特定技能1号・2号</h1>
      </b-col>
    </b-row>
    <b-row class="flex-row">
      <b-col
        md="6"
        class="tokutei-left pl-5"
        data-aos="fade-down"
        data-aos-delay="400"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="mt-5 pt-4">
          <p class="tokutei-condition">在留資格の付与要件</p>
          <div class="condition-txt1 text-left mt-5">
            <p>
              <font-awesome-icon
                icon="fa-solid fa-circle"
              />雇用企業・受入れ機関
            </p>
            <div class="tokutei-content-sub">
              <p>① 外国人と結ぶ雇用契約が適切</p>
              <ul>
                <li>報酬額が日本人と同等以上</li>
                <li>雇用契約が継続的なものであること</li>
              </ul>
              <p>② 機関自体が適切</p>
              <ul>
                <li>5 年以内に出入国労働法令違反がないこと</li>
              </ul>
            </div>
          </div>
          <div class="condition-txt1 text-left mt-5">
            <p><font-awesome-icon icon="fa-solid fa-circle" />労働者</p>
            <div class="tokutei-content-sub">
              <p>① 18 歳以上であること</p>
              <p class="text-danger">
                ② 技能試験及び日本語試験に合格していること
              </p>
              <ul>
                <li>
                  技能試験 :
                  <p>特定産業分野の業務区分に対応する試験</p>
                  <p>（技能実習２号完了人は試験免除）</p>
                </li>
                <li>
                  日本語試験 :
                  <p>国際交流基金日本語基礎テスト</p>
                  <p>または日本語能力試験（N4 以上）</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex tokutei-img">
          <div>
            <img src="@/assets/tokutei-img1.png" width="100%" alt="株式会社ASUKA、人材紹介" />
          </div>
          <div>
            <img src="@/assets/tokutei-img2.png" width="100%" alt="株式会社ASUKA、人材紹介" />
          </div>
        </div>
      </b-col>
      <b-col
        md="6"
        ass="tokutei-right pr-5"
        data-aos="fade-up"
        data-aos-delay="400"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <table class="mx-auto mt-5 compare-table">
          <tr>
            <td class="pt-4 pr-4 pb-2 pl-0 compare-td">
              <div class="compare-title">
                <p class="no-wrap-txt">職種</p>
                <p class="no-wrap-txt">(期間制限)</p>
              </div>
            </td>
            <td class="pt-4 pr-4 pb-2 pl-0 compare-td">
              <div class="compare-title">
                <p class="no-wrap-txt">特定技能1号</p>
                <p class="no-wrap-txt">あり（通算５年）</p>
              </div>
            </td>
            <td class="pt-4 pr-4 pb-2 pl-0 compare-td">
              <div class="compare-title">
                <p class="no-wrap-txt">特定技能２号</p>
                <p class="no-wrap-txt">なし</p>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-item">① 建設業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
          </tr>
          <tr>
            <td class="text-item">② 造船・船用工業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
          </tr>
          <tr>
            <td class="text-item">③ 自動車整備業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td>
              <font-awesome-icon icon="fa-solid fa-square-check" /><span
                class="position-absolute"
                >(未定)</span
              >
            </td>
          </tr>
          <tr>
            <td class="text-item">④ 航空業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td>
              <font-awesome-icon icon="fa-solid fa-square-check" /><span
                class="position-absolute"
                >(未定)</span
              >
            </td>
          </tr>
          <tr>
            <td class="text-item">⑤ 宿泊業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑥ 介護</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑦ ビルクリーニング</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑧ 農業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑩ 飲食料品製造業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑪ 外食業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑫ 素形材産業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑬ 産業機械製造業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-item">⑭ 電気電子情報産業</td>
            <td><font-awesome-icon icon="fa-solid fa-square-check" /></td>
            <td></td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    handleBack() {
      this.$router.back();
    },
    ...mapActions(["setTab"]),
  },
  mounted() {
    this.setTab(0);
  },
};
</script>

<style scoped>
.tokutei-info {
  background-image: url("../assets/asuka_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10px 100px 10px;
  overflow: hidden;
  font-size: 1.1rem;
}
.back-area {
  float: left;
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
  position: fixed;
  left: 10px;
  top: 80px;
  z-index: 1000;
}
.back-area span {
  font-size: 20px;
  display: flex;
  align-content: center;
  font-weight: bold;
  text-decoration: underline;
}
.back-btn {
  font-size: 40px;
}
.compare-table {
  width: 90%;
}
#tokutei-title {
  /* color: #fff; */
  font-weight: bolder;
  text-align: left;
}
.compare-title {
  background: #292a54;
  border-radius: 19px;
  padding: 10px 10px 5px 0px;
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.text-item {
  text-align: left;
  left: 5%;
}
.tokutei-img {
  flex-direction: row;
}
.tokutei-condition {
  background: #292a54;
  position: relative;
  border-radius: 9px;
  padding: 13px;
  width: 90%;
  color: #fff;
  margin-right: auto;
  font-weight: bolder;
  font-size: 22px;
}
.condition-txt1 {
  position: relative;
  width: 80%;
  left: 10%;
}
.tokutei-content-sub {
  margin-left: 5%;
}
@media (max-width: 992px) {
  .tokutei-img {
    flex-direction: column !important;
  }
}
@media (max-width: 768px) {
  .compare-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .compare-td {
    padding: 10px 10px 10px 1px !important;
  }
  .tokutei-left,
  .tokutei-right {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .tokutei-condition {
    width: 85%;
    position: relative;
    margin-left: auto;
  }
  .condition-txt1 {
    left: unset;
    width: 100%;
  }
  .compare-table {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  #tokutei-title {
    font-size: 1.5rem !important;
    text-align: center;
    margin-top: 40px;
  }
  .condition-txt1 {
    padding-left: 5px;
  }
}
@media screen and (max-width: 500px) {
  .tokutei-left,
  .tokutei-right {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}
.no-wrap-txt {
  white-space: nowrap;
}
</style>