import Vue from 'vue'
import './plugins/'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'jquery'
AOS.init({
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBars, faLocationDot, faPhone, faEnvelope,faAngleLeft,faAngleRight,faFax,faLongArrowRight,faKey,faQuoteLeft,faCircle,faSquareCheck,faCircleLeft,faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import {faFacebook,faTwitter,faInstagram,faYoutube} from '@fortawesome/free-brands-svg-icons'


/* add icons to the library */
library.add(faBars,faLocationDot,faPhone,faEnvelope,faFacebook,faTwitter,faInstagram,faYoutube,faAngleLeft,faAngleRight,faFax,faLongArrowRight,faKey,faQuoteLeft,faCircle,faSquareCheck,faCircleLeft,faAnglesUp)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
