<template>
  <div class="home">
    <transition name="fade" mode="out-in">
      <!-- <div v-show="isLoading" class="loader">
    <b-img src="@/assets/asuka_introduce.gif"></b-img>
   </div> -->
    </transition>

    <!-- HEADER 
            =================-->
    <homeHeader />

    <certificate />

    <!-- ABOUT 
            =================-->
    <homeAbout />

    <!-- HOW TO WORK 
            =================-->
    <homeCorporate />

    <!-- SERVICES 
            =================-->

    <homeService />

    <!-- BLOG 
            =================-->
    <homeTeams />
    <!-- <homeBlog /> -->
    <!-- CLIENTS
            =================-->

    <homeSdgs />
    <!-- FOOTER 
            =================-->
    <!-- <homeFooter /> -->
    <div class="scroll-top" id="scroll-top" @click="handleMoveToTop">
      <font-awesome-icon icon="fa-solid fa-angles-up" />
    </div>
  </div>
</template>

<script>
// import naviBar from '@/components/Navigation.vue'
import homeHeader from "@/components/Header.vue";
import homeAbout from "@/components/About.vue";
import homeService from "@/components/Service.vue";
import homeCorporate from "@/components/Corporate.vue";
import homeTeams from "@/components/Teams.vue";
import homeSdgs from "@/components/Sdgs.vue";
import certificate from "@/components/Certificate.vue";
import { mapActions } from "vuex";
// import homeFooter from '@/components/Footer.vue'
// import topBar from '@/components/TopBar.vue'
export default {
  name: "Home",
  components: {
    // naviBar,
    homeHeader,
    homeAbout,
    homeService,
    homeCorporate,
    homeTeams,
    homeSdgs,
    certificate,
    // homeFooter,
    // topBar
  },
  mounted() {
    let _this = this;
    this.setTab(1);
    setTimeout(function () {
      _this.isLoading = false;
    }, 3000);
    let hash = this.$route.hash;
    if (hash) {
      setTimeout(() => {
        let id = hash.substring(1);
        const elemetn = document.getElementById(id);
        if (elemetn) {
          switch (id) {
            case "conent":
               this.setTab(1);
              break;
            case "home-services":
              this.setTab(3);
              break;
            case "home-footer":
              this.setTab(4);
              break;
          }
          elemetn.scrollIntoView({ behavior: "smooth", delay: 0 });
        }
      }, 2000);
    }
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    ...mapActions(["setTab"]),
    handleMoveToTop() {
      const elemetn = document.getElementById("conent");
      elemetn.scrollIntoView({ behavior: "smooth", delay: 0 });
    }
  }
};
</script>
<style scoped>
:deep(.fade-enter-active),
:deep(.fade-leave-active) {
  transition: opacity 0.4s;
}

:deep(.fade-enter),
:deep(.fade-leave-to)
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.loader {
  position: fixed;
  z-index: 10000;
}
.scroll-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  font-size: 3rem;
  color: #111c55;
  display: none;
  z-index: 1000;
}
@media (max-width: 576px) {
  .home {
    overflow: hidden !important;
  }
  .scroll-top {
     font-size: 2rem;
    bottom: 50px;
    right: 30px;
  }
  
}
</style>
