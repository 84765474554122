<template>
  <section id="home-sdgs" class="client p-5">
    <b-container class="sdsg-container" fluid>
      <b-row>
        <b-col sm="12" class="mx-auto mb-5">
          <div
            class="mx-auto"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="700"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h1 class="vision-txt mt-5">Sustainable Development Goals</h1>
            <h2 class="mt-5 font-weight-bold-vision">
              「社会と地球のために、私たちのSDGsプロミス」
            </h2>
            <div class="text-introduce">
              <p class="text-center text-white-space mt-5">
                {{ $t("sdg_introcude") }}
              </p>
              <p class="text-left"></p>
            </div>
          </div>
        </b-col>
        <b-col sm="10" class="client-item mx-auto pt-5">
          <b-img
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
            fluid
            src="../assets/sdg/sdgs-logo.png"
            class="sdgs-log mb-5"
            alt="株式会社ASUKA,人材紹介"
          ></b-img>
        </b-col>
      </b-row>
      <carousel
        :autoplay="true"
        :loop="true"
        :smartSpeed="700"
        :items="5"
        :nav="false"
        :dots="false"
        id="clients-list"
        data-aos="zoom-in"
        data-aos-delay="100"
        data-aos-duration="700"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
        class="mt-4"
      >
        <div class="client-item">
          <b-img
            fluid
            src="../assets/sdg/sdg_3.png"
            class="img-fluid"
            alt="株式会社ASUKA,人材紹介"
          ></b-img>
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_4.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_5.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_7.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_8.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_10.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_11.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_13.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_15.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
        <div class="client-item">
          <b-img src="../assets/sdg/sdg_16.png" class="img-fluid" alt="株式会社ASUKA,人材紹介" />
        </div>
      </carousel>
    </b-container>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "home-sdgs",
  components: { carousel },
};
</script>

<style scoped>
#home-sdgs {
  /* background: #e9ecef; */
  /* background-image: url("../assets/background_wave.png");
  background-size: cover; */
  /* background: #fcfcfd; */
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  font-size: 1.1rem;
  padding-bottom: 10 !important;
  margin-top: 5%;
  margin-bottom: 10%;
}
.text-white-space {
  white-space: pre;
}

@media (min-width: 2400px) {
  .sdsg-container {
    max-width: 1480px;
  }
}
@media (min-width: 768px) {
  .text-introduce {
    width: 90%;
    margin: auto;
  }
}

:deep(.img-fluid) {
  max-width: 55% !important;
  margin: auto;
}
.sdgs-log {
  max-width: 35% !important;
}
@media (max-width: 768px) {
  :deep(.img-fluid) {
    max-width: 95% !important;
  }
  .sdgs-log {
    max-width: 70% !important;
  }
}
@media (max-width: 576px) {
  #home-sdgs {
    padding: 3rem 0.5rem !important;
  }
}
</style>