<template>
  <b-container class="cvtech-container pl-0 pr-0" fluid>
    <b-row class="cvtech-header-row w-100 ml-0 mr-0 pl-0 pr-0">
      <b-col class="w-100 ml-0 mr-0 pl-0 pr-0">
        <div class="cvtech-header">
          <figure class="mb-0">
            <img
              src="@/assets/cvtech-header.png"
              style="width: 100%"
              alt="株式会社ASUKA"
              srcset=""
            />
          </figure>
        </div>
        <div class="cvtech-content">
          <div class="cvtech-content-div">
            <h1>ベトナム技術訓練センター</h1>
            <br />
            <p>
              ベトナムの工科大学や工業大学と提携し、ハノイ市に技術訓練センターを設立しました。<br />
              我々は、ベトナムの日系企業や日本企業への就職を希望する大学卒業生を受け入れ、
              800㎡の工場で<br />
              工作機械操作や各種設計ソフトの教育を提供しています。
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="cvtech-back-area" @click="handleBack">
          <font-awesome-icon class="back-btn" icon="fa-solid fa-circle-left" />
        </div>
      </b-col>
    </b-row>
    <b-row class="cvtech-content-sub w-100 pl-0 pr-0 ml-0 mr-0 mt-5">
      <b-col
        class="w-100"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        <p>
          ベトナムの工科大学や工業大学と提携し、ハノイ市に技術訓練センターを設立しました。<br />
          我々は、ベトナムの日系企業や日本企業への就職を希望する大学卒業生を受け入れ、
          800㎡の工場で<br />
          工作機械操作や各種設計ソフトの教育を提供しています。
        </p>
      </b-col>
    </b-row>
    <b-row class="cvtech-overview-row w-100 pl-0 pr-0 ml-0 mr-0">
      <b-col class="w-100">
        <div class="cvtech-overview-content">
          <div
            class="content-1"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h2><strong>主機械設備</strong></h2>
            <p>NC旋盤・マシニングセンター・汎用工作機械、...</p>
          </div>
          <div
            class="content-2"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h2><strong>設計ソフト</strong></h2>
            <p>AutoCad、Solidworks、Inventor、MasterCAM、NX、...</p>
          </div>
          <div
            class="content-3"
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <h1>教育内容</h1>
            <p>機械加工に関する知識を教育しております。</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="cvtech-production-row"
                data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
    >
      <b-col md="6" class="production-left-col">
        <div class="production-left">
          <div class="cvtech-production-img">
            <figure>
              <img src="@/assets/cvt_1.png" alt="株式会社ASUKA、人材紹介" srcset="" />
              <figcaption>機械部品製図</figcaption>
            </figure>
          </div>
          <div class="cvtech-production-img">
            <figure>
              <img src="@/assets/cvt_2.png" alt="株式会社ASUKA、人材紹介" srcset="" />
              <figcaption>
                プログラム作成
              </figcaption>
            </figure>
          </div>
          <div class="cvtech-production-img">
            <figure>
              <img src="@/assets/cvt_4.png" alt="株式会社ASUKA、人材紹介" srcset="" />
              <figcaption>完成品</figcaption>
            </figure>
          </div>
        </div>
      </b-col>
      <b-col md="6" class="production-right-col">
        <div class="production-right">
          <div class="cvtech-production-img2">
            <figure>
              <img src="@/assets/cvt_6.png" alt="株式会社ASUKA、人材紹介" srcset="" />
              <figcaption>CAD/CAM設計</figcaption>
            </figure>
          </div>
          <div class="cvtech-production-img2 production-5">
            <figure>
              <img src="@/assets/cvt_3.png" alt="株式会社ASUKA、人材紹介" srcset="" />
              <figcaption>機械操作</figcaption>
            </figure>
          </div>
          <div class="cvtech-production-img2">
            <figure>
              <img src="@/assets/cvt_5.png" alt="株式会社ASUKA、人材紹介" srcset="" />
              <figcaption>品質検査</figcaption>
            </figure>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="cvtech-process-row w-100 pl-0 pr-0 ml-0 mr-0">
      <b-col class="w-100">
        <div class="cvtech-process-content">
          <h1
            data-aos="zoom-in"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >エンジニアのトレーニングプロセス</h1>
          <div class="cvtect-process-content-sub"
            data-aos="zoom-in"
            data-aos-delay="600"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <p class="cvtect-process-content-title">卒業募集</p>
            <p>
              ・<br />
              ・<br />
              ・<br />
              ・
            </p>
            <p class="cvtect-process-content-title">日本語教育・技術訓練</p>
            <p>
              ・<br />
              ・<br />
              ・<br />
              ・
            </p>
            <p class="cvtect-process-content-title">実技テスト</p>
            <p>
              ・<br />
              ・<br />
              ・<br />
              ・
            </p>
            <p class="cvtect-process-content-title">配属</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.cvtech-container {
  font-size: 1.1rem;
  color: #000;
}
.cvtech-header {
  position: relative;
}
.cvtech-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  color: #fff;
}
.cvtech-content-div {
  position: relative;
  top: 40%;
  left: 10%;
  width: fit-content;
}
.cvtech-content-div h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
  -webkit-animation-duration: 1s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomInDown";
  animation-delay: 0.4s;
}
.cvtech-content-div p {
  -webkit-animation-duration: 1s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 0.9s;
}
.cvtech-content-sub {
  display: none;
}
.cvtech-overview-row {
  padding-top: 10%;
  background-image: url("../assets/asuka_bg.png");
  background-size: cover;
  background-size: 100% 100%;
}
.cvtech-overview-row .content-2 {
  margin-top: 65px;
  line-height: 4;
}
.cvtech-overview-row .content-1 {
  line-height: 4;
}
.cvtech-overview-content {
  height: 620px;
}
.cvtech-overview-row .content-3 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)!important;
  line-height: 4;
  width: 100%;
}
.cvtech-overview-row .content-3 h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}

.cvtech-production-img {
  width: 100%;
}
.cvtech-production-img figure {
  padding: 100px 50px;
  width: fit-content;
  float: right;
}
.cvtech-production-img figure img {
  /* width: 100%;
    max-width: 450px; */
  width: 450px;
  height: 290px;
}
.cvtech-production-img figure figcaption,
.cvtech-production-img2 figure figcaption {
  margin-top: 15px;
}
.production-left {
  display: flex;
  flex-direction: column;
}
.production-right {
  display: flex;
  flex-direction: column;
  margin-top: 245px;
}
.cvtech-production-img2 {
  width: 100%;
  /* padding-top: 200px; */
}
.cvtech-production-img2 figure {
  padding: 100px 50px;
  width: fit-content;
  float: left;
}

.cvtech-production-img2 figure img {
  /* width: 100%;
    max-width: 450px; */
  width: 450px;
  height: 290px;
}
.production-5 {
  margin-top: 40px;
}
.cvtech-process-row {
  margin-top: 10%;
  background-image: url("../assets/background_wave.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 150px;
}
.cvtech-process-row h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.cvtect-process-content-sub {
  margin-top: 120px;
  padding: 50px 0 70px 0;
}
.cvtect-process-content-title {
  font-weight: bolder;
}
#cvtech-back-area {
  display: none;
}
#cvtech-back-area {
  font-size: 40px;
  text-align: left;
  position: fixed;
  left: 10px;
  top: 80px;
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 1300px) {
  .cvtech-content-div {
    left: unset;
    margin: auto;
    top: 30%;
  }
  .cvtech-production-img2 figure img,
  .cvtech-production-img figure img {
    width: 395px;
    height: 255px;
  }
  .cvtech-production-img2 figure,
  .cvtech-production-img figure {
    padding: 50px;
  }
  .production-right {
    margin-top: 165px;
  }
}
@media screen and (max-width: 992px) {
  .cvtech-content-div {
    top: 15%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .production-right {
    margin-top: 115px;
  }
  .cvtech-production-img2 figure,
  .cvtech-production-img figure {
    padding: 25px;
  }
  .cvtech-production-img2 figure img,
  .cvtech-production-img figure img {
    width: 300px;
    height: 193px;
  }
}
@media screen and (max-width: 768px) {
  .cvtech-content-div h1 {
    font-size: 2rem;
  }
  .cvtech-content-div p {
    font-size: 0.85rem;
  }
  .cvtech-production-img2 figure,
  .cvtech-production-img figure {
    float: unset;
    left: 50%;
    position: relative;
    width: 80%;
    transform: translateX(-50%);
  }
  .cvtech-production-img2 figure img,
  .cvtech-production-img figure img {
    width: 100%;
    height: auto;
    /* height: 290px; */
  }
  .production-right {
    margin-top: unset;
  }
  .production-5 {
    margin-top: unset;
  }
}
@media screen and (max-width: 576px) {
  .cvtech-content-div p {
    display: none;
  }
  .cvtech-content-div {
    top: 45%;
  }
  .cvtech-content-sub {
    display: block;
    text-align: left;
  }
  .content-2 {
    margin-top: 50px;
  }
  .content-3,
  .content-1,
  .content-2 {
    line-height: unset !important;
  }
  .cvtech-overview-content {
    height: 500px;
  }
  .cvtech-container h1 {
    font-size: 1.5rem;
  }
    .cvtech-container h2 {
    font-size: 1.4rem;
  }
}
</style>