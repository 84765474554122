<template>
  <b-container class="mha-container pl-0 pr-0" fluid>
    <b-row class="mha-header-row">
      <b-col class="w-100">
        <div class="mha-header">
          <figure>
            <img
              src="@/assets/mha-header.png"
              alt="株式会社ASUKA"
              style="width: 100%"
            />
          </figure>
          <div class="mha-header-content">
            <div>
              <div class="logo-company">
                <img
                  src="@/assets/mha-logo.png"
                  class="logo"
                  width="20%"
                  alt="株式会社ASUKA"
                />
                <p class="company">
                  <span>MHA Mechanical Enginerring</span><br />
                  <span>機械部品製造加工・切削工場</span>
                </p>
              </div>
              <p class="mha-header-content-sub">
                MHA Mechanical Engineering
                株式会社は北部地域の機械工学市場における顕著な業績ある会社です。<br />
                2016年に設立され運営する長年を得て、MHAは合計面積が3000ｍ<sup>2</sup>の二か所の向上お所持し、<br />
                百人以上の専門職高い従業員を雇用することで、その産業で地位を獲得しました。
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="mha-back-area" @click="handleBack">
          <font-awesome-icon class="back-btn" icon="fa-solid fa-circle-left" />
        </div>
      </b-col>
    </b-row>
    <b-row class="mha-header-content-sub1 w-100 pl-0 pr-0 ml-0 mr-0 mt-5">
      <b-col class="w-100">
        <p
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          MHA Mechanical Engineering
          株式会社は北部地域の機械工学市場における顕著な業績ある会社です。<br />
          2016年に設立され運営する長年を得て、MHAは合計面積が3000ｍ<sup>2</sup>の二か所の向上お所持し、<br />
          百人以上の専門職高い従業員を雇用することで、その産業で地位を獲得しました。
        </p>
      </b-col>
    </b-row>
    <b-row class="mha-overview-row pl-0 pr-0 ml-0 mr-0">
      <b-col class="w-100">
        <div
          class="mha-overview-content"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <h1>MHAについて</h1>
          <p>
            MHAの主な製品は次ように紹介させていただきます。<br />
            <strong>精密機械加工、機械部品加工、</strong>備品加工、自動機の設計・製作、<br />
            自動搬送システム、コンベア・台車、その産業製品の製造組立。<br />
            MHAは<strong>機械加工分野</strong>で突出しているのみならず、近年<strong>プラスチェック分野</strong>にも<br />
            進出しポジティブな結果をもたらしております。当社のカスタム形状に<br />
            気づいたプラスチェックトレーの製品は真空・帯電防止及び<br />
            RO 水洗上といった高度な技術を使用し製造されたため優れた品質が<br />
            保証されます。その上で我が社とパートナー様が増えていてMHAの新たな<br />
            道を切り開いてきました。MHAは現在、精密機械加工機械部品<br />
            加工部品加工など業界トップの地位にあり主要な顧客は<br />
            <strong>日本、韓国、アメリカ、EU</strong
            >の企業を始め、外資系企業です。
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row class="mha-process-row pl-0 pr-0">
      <b-col class="w-100">
        <div
          class="mha-process-content"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <h1>製造プロセス</h1>
          <figure>
            <img
              src="@/assets/mha-process.png"
              style="width: 100%; max-width: 1195px"
              alt="株式会社ASUKA"
              srcset=""
            />
          </figure>
        </div>
      </b-col>
    </b-row>
    <b-row class="mha-device-row pl-0 pr-0 ml-0 mr-0">
      <b-col class="w-100">
        <div class="mha-device-content">
          <h1>機械設備</h1>
          <div
            class="mha-device-img"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="3000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_1.jpg" alt="株式会社ASUKA" />
                <figcaption>HUYND AWIA I-CUT 380TD 01</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_2.jpg" alt="株式会社ASUKA" />
                <figcaption>MAKINO PS65</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_3.jpg" alt="株式会社ASUKA" />
                <figcaption>TC500</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_4.jpg" alt="株式会社ASUKA" />
                <figcaption>FANUC ROBODRILL&alpha;DIBADV Plus</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_5.jpg" alt="株式会社ASUKA" />
                <figcaption>MAKINO SLIM3N</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_6.jpg" alt="株式会社ASUKA" />
                <figcaption>MITSUBISHI</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_7.jpg" alt="株式会社ASUKA" />
                <figcaption>JSJD T640 CNS MACHINE</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_8.jpg" alt="株式会社ASUKA" />
                <figcaption>MAKINO NC フライス盤</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_9.jpg" alt="株式会社ASUKA" />
                <figcaption>ROKU-ROKU CNS フライス盤</figcaption>
              </figure>
            </div>
            <div class="device-img device-img-11">
              <figure>
                <img src="@/assets/mha_10.jpg" alt="株式会社ASUKA" />
                <figcaption>HUYNDA WAIA I-CUT 380TD 02</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/mha_11.jpg" alt="株式会社ASUKA" />
                <figcaption>TAKISAWA CNC フライス盤</figcaption>
              </figure>
            </div>
            <div class="device-img device-img-11">
              <figure>
                <img src="@/assets/mha_12.jpg" alt="株式会社ASUKA" />
                <figcaption>NILGATA フライス盤</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mha-production-row pl-0 pr-0 ml-0 mr-0">
      <b-col class="w-100">
        <div class="mha-production-content">
          <h1>主要製品</h1>
          <div
            class="mha-device-img"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="3000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_1.jpg" alt="株式会社ASUKA" />
                <figcaption>ROLLER 治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_2.jpg" alt="株式会社ASUKA" />
                <figcaption>CHECK 治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_3.jpg" alt="株式会社ASUKA" />
                <figcaption>機械組み立て治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_4.jpg" alt="株式会社ASUKA" />
                <figcaption>製品治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_5.jpg" alt="株式会社ASUKA" />
                <figcaption>回路治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_6.jpg" alt="株式会社ASUKA" />
                <figcaption>BENDING CROWN BASE 治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_7.jpg" alt="株式会社ASUKA" />
                <figcaption>梱包用操作盤</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_8.jpg" alt="株式会社ASUKA" />
                <figcaption>ゴミ分類ベルトコンベア</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_9.jpg" alt="株式会社ASUKA" />
                <figcaption>MHA 023 治具</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_10.jpg" alt="株式会社ASUKA" />
                <figcaption>静電気防止製品台車</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_11.jpg" alt="株式会社ASUKA" />
                <figcaption>ステンレス2段台車</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_12.jpg" alt="株式会社ASUKA" />
                <figcaption>日本輸出向けステンレス枠</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_13.jpg" alt="株式会社ASUKA" />
                <figcaption>ミニベルトコンベア</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_14.jpg" alt="株式会社ASUKA" />
                <figcaption>治具オールオダーライン</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_15.jpg" alt="株式会社ASUKA" />
                <figcaption>電子回路浴溶接機</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_16.jpg" alt="株式会社ASUKA" />
                <figcaption>乾燥機</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_17.jpg" alt="株式会社ASUKA" />
                <figcaption>ステンレス棚</figcaption>
              </figure>
            </div>
            <div class="device-img">
              <figure>
                <img src="@/assets/sh_18.jpg" alt="株式会社ASUKA" />
                <figcaption>精密機械治具</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    handleBack() {
      this.$router.back();
    },
  }
};
</script>

<style scoped>
.mha-container {
  font-size: 1.1rem;
  color: #000;
}
.mha-header {
  position: relative;
}
.mha-header-content {
  width: 60%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  color: #fff;
}
.mha-header-content div {
  position: relative;
  margin: auto;
  top: 30%;
  /* transform: translateY(-30%); */
  height: fit-content;
  margin-left: 15px;
  width: fit-content;
}

.logo-company {
  display: inline-flex;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomIn";
  animation-delay: 0.3s;
  /* width: 100%; */
}
.logo-company .logo {
  /* max-width: 300px; */
}
.logo-company .company {
  padding: 2% 10px;
  -webkit-animation-duration: 1s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "zoomInDown";
  animation-delay: 0.4s;
}
.mha-header-content-sub {
  -webkit-animation-duration: 1s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: "fadeInDown";
  animation-delay: 0.9s;
  margin-left: 5%;
  width: fit-content;
}
.logo-company .company span {
  font-weight: bolder;
  font-size: 3rem;
}
.mha-header-content-sub1 {
  display: none;
}
.mha-overview-row {
  margin-top: 10%;
}
.mha-overview-row h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.mha-overview-row p {
  margin-top: 50px;
  line-height: 2;
  letter-spacing: 0.1em;
}
.mha-process-row {
  margin-top: 10%;
  background-image: url("../assets/background_wave.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mha-process-row h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
  margin-top: 200px;
}
.mha-process-row figure {
  margin-top: 100px;
}
.mha-device-row {
  margin-top: 10%;
}
.mha-device-img {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 80%;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-evenly;
  max-width: 1480px;
  margin-top: 50px;
}
.mha-device-img .device-img {
  width: 33.3%;
  position: relative;
}
.mha-device-img .device-img figure {
  padding: 10%;
  /* width: 60%; */
  position: relative;
}
.mha-device-img .device-img figure img {
  width: 100%;
  max-height: 300px;
}
.mha-device-img .device-img figure figcaption {
  margin-top: 15px;
}
.mha-device-content h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.mha-production-row {
  margin-top: 10%;
  margin-bottom: 150px;
  background-image: url("../assets/asuka_bg.png");
  background-size: cover;
  background-size: 100% 100%;
}
.mha-production-content h1 {
  font-weight: bolder;
  letter-spacing: 0.1em;
}
#mha-back-area {
  display: none;
}
#mha-back-area {
  font-size: 40px;
  text-align: left;
  position: fixed;
  left: 10px;
  top: 80px;
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 1700px) {
  .mha-header-content {
    width: 100%;
  }
  .logo-company .company span {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1300px) {
  .mha-header-content div {
    margin-left: unset;
    width: unset;
    top: 25%;
  }
}
@media screen and (max-width: 992px) {
  .mha-header-content div {
    margin-left: unset;
    width: unset;
    top: 5%;
  }
  .logo-company .company span {
    font-size: 1.5rem;
  }
  .mha-device-img .device-img {
    width: 45%;
  }
}
@media screen and (max-width: 768px) {
  .mha-header-content-sub {
    /* display: none; */
    font-size: 0.85rem;
  }
  .mha-header-content div {
    /* top: 15%;
        left: 0%; */
    text-align: center;
  }
  .logo-company .company span {
    font-size: 1.1rem;
  }
  .mha-overview-row p {
    text-align: left;
  }
  .mha-device-img .device-img {
    width: 100%;
  }
  .mha-device-img .device-img figure {
    padding: 2%;
  }
}
@media screen and (max-width: 576px) {
  .mha-header-content-sub {
    display: none;
  }
  .mha-header-content-sub1 {
    display: block;
    text-align: left;
  }
  .mha-header-content div {
    /* top: 20%; */
  }
  .mha-device-img .device-img figure {
    padding: 1%;
  }
  .mha-container h1 {
    font-size: 1.5rem;
  }
}
</style>